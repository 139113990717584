import React, { useEffect, useState } from 'react';

import {Button, Typography} from 'antd';

import {EyeOutlined, EyeInvisibleOutlined} from '@ant-design/icons';

const {Text} = Typography;

function PracticePassword({data}) {
  const [password, setPassword] = useState('aaaa');
  const [show, setShow] = useState(false);
  const [icon, setIcon] = useState(<EyeOutlined />);
  useEffect(() => {
    if (show) {
      setIcon(<EyeInvisibleOutlined />);
    } else {
      setIcon(<EyeOutlined />);
    }
  }, [show]);
  return (
    <>
      <Text code>{show ? data.practice.password : '****************'}</Text>
      <a onClick={() => { setShow(!show); }}>{icon}</a>
    </>
  );
}

export default PracticePassword;
