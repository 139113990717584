import { Row, Col } from "antd";
import CardCategory from "../CardCategory";
import { SoundOutlined, TrophyOutlined } from "@ant-design/icons";
import './index.less';

function LastEditions() {
    return (
        <div className="schedules__last__editions">
            <div className="schedules__last__editions__description">
              <p>
                Brevemente vamos disponibilizar novas atividades, como eventos e
                formações exclusivamente gratuitas, fique atento.
              </p>
              <p>
                Não deixe de conferir os últimos eventos e formações disponíveis.
              </p>
            </div>
            <Row gutter={16}>
              <Col xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:12}} xl={{span: 10, offset: 2}}>
                <CardCategory 
                  to="/events" 
                  icon={<SoundOutlined/>} 
                  title="Eventos"
                  image="/images/calendar3.svg"
                />
              </Col>
              <Col xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:12}} xl={{span: 10}}>
                <CardCategory
                  to="/formations"
                  icon={<TrophyOutlined />}
                  title="Formações"
                  image="/images/formation1.svg"
                />
              </Col>
          </Row>
        </div>
    );
}

export default LastEditions;
