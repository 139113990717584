import React from "react";

import { Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router-dom";

import "./index.less";

const { Link } = Typography;

export default ({ to }) => {
  const navigate = useNavigate();
  return (
    <div className="back">
      <Link
        onClick={() => {
          if (to) {
            navigate(to);
          } else {
            navigate(-1);
          }
        }}
      >
        <LeftOutlined /> Voltar
      </Link>
    </div>
  );
};
