import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Divider, notification, Row, Space, Spin, Tag, Typography } from "antd";
import _service from "@netuno/service-client";
import { USER_SKILLS } from "../../common/consts";
import Back from "../../components/Back";
import Loading from "../../components/Loading";
import HistoryTabs from "../../components/HistoryTabs";
import "./index.less";

function UserProfile() {
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const params = useParams();
    const navigate = useNavigate();
    const socialNetworks = {
        instagram: <FaInstagram size={30} />,
        linkedin: <FaLinkedin size={30} />,
        facebook: <FaFacebook size={30} />,
        twitter: <FaTwitter size={30} />
    };

    const fetchUserInfo = () => {
        setLoading(true);

        const data = {
            user: params.user
        };

        _service({
            url: 'people',
            data,
            success: (response) => {
                if (response.json && response.json.result) {
                    console.log(response.json.data);
                    setUserInfo(response.json.data);
                    setLoading(false);
                }
            },
            fail: (error) => {
                console.error("Fetch User Info", error);

                notification["error"]({
                    message: 'Falha ao carregar informações do usuário',
                    description: 'Ocorreu um erro ao carregar as informaçẽs do usuário, por favor tente novamente mais tarde ou entre em contato com o suporte.',
                });
            }
        });
    };

    useEffect(() => {
        fetchUserInfo();
    }, []);

    if (loading) {
        return (
            <Loading page />
        );
    }

    return (
        <div className="user-profile">
            <Back />
            <Row className="user-name">
                <Col span={24}>
                    <div>
                        <Avatar size={{xs: 128, sm: 128, md: 256, lg: 256, xl: 256, xxl: 256 }} src={userInfo?.people?.avatar ? `${_service.config().prefix}/people/avatar?uid=${userInfo?.people?.uid}` : "/images/profile-default.png"} />
                    </div>
                    <Typography.Title>{userInfo.name}</Typography.Title>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col lg={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                    <div>
                        <Typography.Title level={4}>
                            GitHub
                        </Typography.Title>
                    </div>
                    <div>
                        <Typography.Paragraph>
                            {userInfo.people && userInfo.people.github ? (
                                <a href={userInfo.people && userInfo.people.github} target="_blank">
                                    {userInfo.people.github}
                                </a>
                            ) : "-"}
                        </Typography.Paragraph>
                    </div>
                    <div>
                        <Typography.Title level={4}>
                            Empresa
                        </Typography.Title>
                    </div>
                    <div>
                        <Typography.Paragraph>
                            {userInfo.company && userInfo.company.website ? (
                                <a href={userInfo.company && userInfo.company.website} target="_blank">
                                    {userInfo.company.website}
                                </a>
                            ) : "-"}
                        </Typography.Paragraph>
                    </div>
                    <div>
                        <Typography.Title level={4}>
                            Redes Sociais
                        </Typography.Title>
                    </div>
                    <div>
                        <Space wrap>
                            {userInfo.socialNetworks && userInfo.socialNetworks.map((socialNetwork) => (
                                <Typography.Paragraph>
                                    <a href={socialNetwork.link} target="_blank">
                                        {socialNetworks[socialNetwork.code]}
                                    </a>
                                </Typography.Paragraph>
                            ))}
                            {userInfo.socialNetworks && userInfo.socialNetworks.length === 0 && "-"}
                        </Space>
                    </div>
                </Col>
                <Col lg={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                    <div>
                        <Typography.Title level={4}>
                            Habilidades
                        </Typography.Title>
                    </div>

                    <div className="skills">
                        <Space wrap>
                            {
                                USER_SKILLS.map(field =>
                                    userInfo.skills &&
                                    userInfo.skills[field.code] && (
                                        <Tag key={field.code}>{field.name}</Tag>
                                    )
                                )
                            }

                            {
                                userInfo.skills &&
                                Object.values(userInfo.skills)
                                    .every((skill) => !skill) &&
                                "-"
                            }
                        </Space>
                    </div>
                    {
                        userInfo.skills &&
                        Object.keys(userInfo.skills).length > 0 &&
                        userInfo.people &&
                        (
                            <Typography.Paragraph>
                                Com {userInfo.people.experience} {userInfo.people.experience !== 1 ? "anos" : "ano"} de experiência
                            </Typography.Paragraph>
                        )
                    }
                </Col>
            </Row>
            <HistoryTabs user={userInfo.username} />
        </div>
    );
}

export default UserProfile;
