import config from '../config/config.json';
import _auth from '@netuno/auth-client';
import _service from '@netuno/service-client';

_service.config({
    prefix: config.api.services
});

_auth.config({
    storage: 'local'
});
