// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/antd/dist/reset.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dashboard-layout-content > img {\n  margin-top: 20px;\n  max-width: 60%;\n}\n@media screen and (max-width: 768px) {\n  .dashboard-layout-content > img {\n    max-width: 90%;\n  }\n}\n.main__loading {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 50vh;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Main/index.less"],"names":[],"mappings":"AAEA;EAEQ,gBAAA;EACA,cAAA;AADR;AAGQ;EAAA;IACI,cAAA;EAAV;AACF;AAGA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AADJ","sourcesContent":["@import '../../styles/variables.less';\n\n.dashboard-layout-content {\n    > img {\n        margin-top: 20px;\n        max-width: 60%;\n\n        @media screen and (max-width: @mobile-width) {\n            max-width: 90%;\n        }\n    }\n}\n.main__loading {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 50vh;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
