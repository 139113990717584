import React, { useState, useEffect } from "react";
import { Navigate, Link } from "react-router-dom";
import {
  Layout,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  notification,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import _auth from "@netuno/auth-client";
import RecoverModal from "./RecoverModal";
import SiderHome from "../../components/SiderHome";

import "./index.less";

const { Title } = Typography;
const { Content } = Layout;

export default function Login() {
  const [submitting, setSubmitting] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (_auth.isLogged()) {
      window.scrollTo(0, 0);
    }
    
  });

  function onFinish(values) {
    setSubmitting(true);
    const { username, password, remember } = values;
    if (remember) {
      localStorage.setItem("login", JSON.stringify(values));
    } else {
      localStorage.removeItem("login");
    }
    _auth.login({
      username,
      password,
      success: () => {
        setSubmitting(false);
      },
      fail: () => {
        setSubmitting(false);
        if (!_auth.isLogged()) {
          notification["error"]({
            message: "Login Inválido",
            description: "Por favor verifique as credenciais inseridas.",
          });
        }
      },
    });
  }

  function onFinishFailed(errorInfo) {
    console.log("Failed:", errorInfo);
  }

  let initialValues = { remember: true };
  if (localStorage.getItem("login") != null) {
    initialValues = JSON.parse(localStorage.getItem("login"));
  }

  if (_auth.isLogged()) {
    return <Navigate to="/main" replace />;
  }
  return (
    <Layout>
      <Content className="login-container">
        <div className="content-body">
          <div className="content-title">
            <Title>Iniciar sessão</Title>
          </div>
          <p>Inicie sessão com os seus dados.</p>
          <Form
            layout="vertical"
            name="basic"
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              // label="Usuário"
              name="username"
              rules={[
                { required: true, message: "Insira o seu usuário." },
                {
                  type: "string",
                  message: "Usuário inválido, apenas letras minúsculas e números, deve iniciar com uma letra.",
                  pattern: "^[a-z]+[a-z0-9]{1,24}$"
                }
              ]}
            >
              <Input
                placeholder="Usuário"
                prefix={<UserOutlined />}
                loading={submitting}
              />
            </Form.Item>

            <Form.Item
              // label="Senha"
              name="password"
              rules={[{ required: true, message: "Insira a senha." }]}
            >
              <Input.Password
                placeholder="Senha"
                prefix={<LockOutlined />}
                loading={submitting}
              />
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Relembrar</Checkbox>
            </Form.Item>

            <Form.Item>
              <Button
                loading={submitting}
                type="primary"
                className="login-btn"
                htmlType="submit"
              >
                Iniciar Sessão
              </Button>
            </Form.Item>

            <Form.Item style={{ textAlign: "center" }}>
              <Button type="link" onClick={() => setVisible(!visible)}>
                Esqueceu a senha? Clique aqui para recuperar o acesso.
              </Button>
              {visible && (
                <RecoverModal
                  onClose={() => {
                    setVisible(false);
                  }}
                />
              )}
            </Form.Item>

            <hr />
            <span>
              <p>ou</p>
            </span>
            <Link to="/register">
              <Button type="default" className={"register-btn"}>
                Criar Conta
              </Button>
            </Link>
          </Form>
        </div>
      </Content>
      <SiderHome />
    </Layout>
  );
}
