import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Typography, Spin, notification, Button } from "antd";
import Back from "../../components/Back";
import Loading from "../../components/Loading";
import Item from "./Item";

import _service from "@netuno/service-client";

import "./index.less";

const { Title } = Typography;

export default function Formations() {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(true);

  useEffect(() => {
    loadFormations(currentPage);
  }, [currentPage]);

  const loadFormations = (page) => {
    _service({
      url: "/formation/list",
      data: {
        page,
      },
      success: (response) => {
        const limitPerPage = 6;

        if (response.json) {
          setLoading(false);
          setList([...list, ...response.json]);

          if (response.json.length < limitPerPage) {
            setHasMorePages(false);
          }
        }
      },
      fail: (e) => {
        setLoading(false);
        console.error("Formation Service Error", e);
        notification["error"]({
          message: "Lista de Formações",
          description:
            "Não foi possível carregar a lista de formações, tente mais tarde ou entre em contato com o suporte.",
        });
      },
    });
  };

  return (
    <div className="formations">
      {/* <Back to="/main" /> */}
      <div>
        <Title level={3}>Formações</Title>
      </div>
      <div className="formations__introduction">
        <p>
          As formações servem para adquirir novos conhecimentos e partilhar o
          conhecimento adquirido com as outras pessoas.
        </p>
        <p>
          É importante praticar bastante, esclarecer as tuas dúvidas, participar
          sempre que possível e certamente vai conseguir progredir bem.
        </p>
      </div>
      {loading && list.length === 0 && (
        <Loading />
      )}
      <div className="formations-grid">
        {list.map((item) => {
          return <Item key={item.uid} data={item} />;
        })}
        {list.length > 0 && (
          <Button
            className="show-more"
            type="dashed"
            disabled={!hasMorePages}
            loading={loading}
            onClick={() => {
              setLoading(true);
              setCurrentPage(currentPage + 1);
            }}
          >
            Mostrar mais
          </Button>
        )}
      </div>
    </div>
  );
}
