import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Divider,
  Typography,
  Spin,
  Tag,
  notification,
  Tooltip,
  Badge,
} from "antd";

import {
  SoundOutlined,
  TrophyOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import _service from "@netuno/service-client";
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import Core from "../../Core";
import CalendarLinks from "../CalendarLinks";
import LastEditions from "../LastEditions";

import "./index.less";

const { Title, Text } = Typography;

function Schedules({ loggedUserInfo }) {
  const [list, setList] = useState([]);
  const [type, setType] = useState(null);
  const [detail, setDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const servicePrefix = _service.config().prefix;
  const navigate = useNavigate();

  useEffect(() => {
    _service({
      method: "GET",
      url: "/schedules",
      success: (response) => {
        if (response.json) {
          setList(response.json);
          setLoading(false);
        }
      },
      fail: (e) => {
        setLoading(false);
        console.error("Schedules Service Error", e);
        notification["error"]({
          message: "Cronograma",
          description:
            "Não foi possível carregar o cronograma, tente mais tarde ou entre em contato com o suporte.",
        });
      },
    });
  }, []);

  if (loading) {
    return (
      <div className="schedules__loading"><Spin /></div>
    );
  }

  return (
    <div style={{overflow:'hidden'}}>
      {!loading && list.length == 0 ? (
        <>
          <div className="schedules__empty">
            <Title level={3}>Olá {loggedUserInfo.name}, seja bem vindo.</Title>
            <p>Estamos preparando novas sessões assim que estiver disponível será notificado por e-mail.</p>
            <p>Enquanto isso veja o conteúdo disponível e gravado das sessões anteriores.</p>
            <p>Participe da nossa comunidade no <a href="https://discord.gg/4sfXG6YWFu">Discord</a>.</p>
            <Divider />
            <Title level={3}>Não perca as atividades realizadas:</Title>
            <LastEditions/>
          </div>
        </>
      ) : (
        <>
          <Title level={3}>Agende seu horário, em breve iniciaremos:</Title>
          <div className="schedules-grid">
            {list
              .slice(0, list.length)
              .map(
                ({
                  uid,
                  type,
                  name,
                  number,
                  description,
                  start_date,
                  end_date,
                  session,
                  registered,
                  image,
                }) => {
                  return (
                    <>
                      <div
                        className="schedules__item"
                        onClick={() => {
                          setType(type);
                          navigate(`/${type}/session/${session.uid}`);
                        }}
                      >
                        <div className="schedules__item_body">
                          <Badge.Ribbon
                            text={
                              <span>
                                <ClockCircleOutlined />{" "}
                                <Countdown
                                  date={Core.toUserLocalMoment(
                                    session.start_date_time
                                  ).toDate()}
                                />
                              </span>
                            }
                            color="purple"
                          >
                            <div
                              className="schedules__item_cover"
                              style={{
                                backgroundImage: `url(/images/${type}/${image})`,
                              }}
                            >
                              <div className="schedules__item_number">
                                {session.number}
                              </div>
                            </div>
                          </Badge.Ribbon>
                          <Title className="schedules__item_title" level={5}>
                            {session.name}
                          </Title>
                          {registered ? (
                            <Text type="success">
                              <CheckOutlined /> O seu lugar está garantido.
                            </Text>
                          ) : (
                            <Text type="warning">
                              <ClockCircleOutlined /> Garanta já o seu lugar.
                            </Text>
                          )}
                          <br />
                          <p className="schedules__item_date">
                            {Core.toUserLocalDateTime(session.start_date_time)}{" "}
                            até {Core.toUserLocalTime(session.end_date_time)}
                            {type == "event" ? (
                              <Tag color="cyan">
                                <SoundOutlined /> Evento
                              </Tag>
                            ) : (
                              <Tag color="lime">
                                <TrophyOutlined /> Formação
                              </Tag>
                            )}
                          </p>
                          <p>
                            <CalendarLinks session={session} />
                          </p>
                        </div>
                        <div className="schedules__item_footer">
                          <p level={5}>{name}</p>
                          <Tooltip
                            placement="topRight"
                            title={
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: session.description,
                                }}
                              ></div>
                            }
                          >
                            <span>
                              <InfoCircleOutlined />
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    </>
                  );
                }
              )}
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = store => {
  const { loggedUserInfo } = store.loggedUserInfoState;
  return {
    loggedUserInfo
  };
};

export default connect(mapStateToProps, {})(Schedules);
