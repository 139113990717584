import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Spin, Typography, notification, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Back from "../../components/Back";
import Loading from "../../components/Loading";
import _service from "@netuno/service-client";

import Item from "./Item";

import "./index.less";

const { Title } = Typography;

export default function Events() {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(true);

  useEffect(() => {
    loadEvents(currentPage);
  }, [currentPage]);

  const loadEvents = (page) => {
    _service({
      url: "/event/list",
      data: {
        page,
      },
      success: (response) => {
        const limitPerPage = 6;

        if (response.json) {
          setLoading(false);
          setList([...list, ...response.json]);

          if (response.json.length < limitPerPage) {
            setHasMorePages(false);
          }
        }
      },
      fail: (e) => {
        setLoading(false);
        console.error("Event Service Error", e);
        notification["error"]({
          message: "Lista de Eventos",
          description:
            "Não foi possível carregar a lista de eventos, tente mais tarde ou entre em contato com o suporte.",
        });
      },
    });
  };

  return (
    <div className="events">
      {/* <Back to="/main" /> */}
      <div>
        <Title level={3}>Eventos</Title>
      </div>
      <div className="events__introduction">
        <p>
          Os eventos servem para realizar a apresentação de novos projetos,
          técnicas de trabalho e ainda realizar a discussão em grupo.
        </p>
        <p>
          Vai ficar por dentro das novidades, expandir para novas possibilidades
          e conhecer mais pessoas.
        </p>
      </div>
      {loading && list.length === 0 && (
        <Loading />
      )}
      <div className="events-grid">
        {list.map((item) => {
          return <Item key={item.uid} data={item} />;
        })}
        {list.length > 0 && (
          <Button
            className="show-more"
            type="dashed"
            disabled={!hasMorePages}
            loading={loading}
            onClick={() => {
              setLoading(true);
              setCurrentPage(currentPage + 1);
            }}
          >
            Mostrar mais
          </Button>
        )}
      </div>
    </div>
  );
}
