import { Link } from "react-router-dom";
import "./index.less";

function CardCategory({to, icon, title, image}) {
  return (
    <Link to={to} className="schedules__card__category__link">
      <div className="schedules__card__category">
        <div className="schedules__card__category__title">
          {icon}
          <span>{title}</span>
        </div>
        <div className="schedules__card__category__image">
          <img alt="" src={image}/>
        </div>
      </div>
    </Link>
  );
}

export default CardCategory;
