export const USER_SKILLS = [
    { code: 'nodejs', name: 'NodeJS' },
    { code: 'sql', name: 'SQL' },
    { code: 'linux', name: 'Linux' },
    { code: 'reactjs', name: 'ReactJS' },
    { code: 'frontend', name: 'Frontend' },
    { code: 'backend', name: 'Backend' },
    { code: 'code', name: 'Programação' },
    { code: 'design', name: 'Design' },
    { code: 'db', name: 'Base de Dados' },
    { code: 'excel', name: 'Excel' },
    { code: 'pdf', name: 'PDF' },
    { code: 'js', name: 'JavaScript' },
    { code: 'py', name: 'Python' },
    { code: 'rb', name: 'Ruby' },
    { code: 'kt', name: 'Kotlin' },
    { code: 'java', name: 'Java' },
    { code: 'php', name: 'PHP' },
    { code: 'wp', name: 'WordPress' },
    { code: 'laravel', name: 'Laravel' },
    { code: 'mysql', name: 'MySQL' },
    { code: 'psql', name: 'PostgreSQL' },
    { code: 'ml', name: 'Machine Learning' },
    { code: 'bigdata', name: 'Big Data' },
    { code: 'rest', name: 'RestAPI' },
    { code: 'svg', name: 'SVG/AI' },
    { code: 'ubuntu', name: 'Ubuntu' },
    { code: 'cs', name: '.Net/C#' },
    { code: 'mq', name: 'Message Queue' }
];