import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { Typography, Form, Input, Button, notification, Spin, Row, Col, Switch, Divider, Select, InputNumber, message } from 'antd';
import { PasswordInput } from "antd-password-input-strength";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { loggedUserInfoAction } from "../../redux/actions";
import Back from "../../components/Back";
import Loading from "../../components/Loading";
import Avatar from "./Avatar";
import Core from '../../Core';
import _service from '@netuno/service-client';

import './index.less';
import { connect } from 'react-redux';

const { Title } = Typography;

function Profile({
    loggedUserInfoAction,
}) {
    const [loading, setLoading] = useState(false);
    const [loadingCountries, setLoadingCountries] = useState(false);
    const [loadingTimeZones, setLoadingTimeZones] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [passwordRequired, setPasswordRequired] = useState(false);
    const [data, setData] = useState(null);
    const [countries, setCountries] = useState([]);
    const [timeZones, setTimeZones] = useState([]);
    const [timeZonesActive, setTimeZonesActive] = useState([]);
    const [timeZoneRequired, setTimeZoneRequired] = useState(false);
    const [avatarImageURL, setAvatarImageURL] = useState('/images/profile-default.png');
    const profileAvatar = useRef(null);
    const profileForm = useRef(null);;
    
    const [form] = Form.useForm();
    const location = useLocation();
    const navigate = useNavigate();

    const linkedinCode = "linkedin";

    const layout = {
        rowGutter: { gutter: [50, 0] },
        spanCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 12 } },
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        labelAlign: "left"
    };

    useEffect(() => {
        if (profileForm.current) {
            onFetchProfile();
        }
    }, [location]);

    useEffect(()=> {
        if (data && data.localization && data.localization.country) {
            setTimeZonesActive(
                timeZones.filter((timeZone) => timeZone.country_code == data.localization.country)
            );
        }
        if (data && countries.length > 0 && timeZones.length > 0) {
            const formInitialData = {
              ...data,
              linkedin: (data.socialNetworks.find(({ code }) => code === linkedinCode) || {}).link,
              socialNetworks: data.socialNetworks.filter(({ code }) => code !== linkedinCode)
            };
            profileForm.current.setFieldsValue(formInitialData);
        }
    }, [data, timeZones]);

    function onFetchProfile() {
        setLoading(true);
        _service({
            method: 'GET',
            url: 'people',
            success: (response) => {
              
                if (response.json.result) {
                    const { data } = response.json;
                    setData(data);
                    console.log(data)
                    if (data.people.avatar) {
                      setAvatarImageURL(`${_service.config().prefix}/people/avatar?uid=${data.people.uid}`);
                    }
                    onFetchCountries();
                } else {
                    notification["warning"]({
                        message: 'Falha ao carregar os Dados',
                        description: response.json.error,
                    });
                    setLoading(false);
                }
            },
            fail: () => {
                setLoading(false);
                notification["error"]({
                    message: 'Falha nos Dados',
                    description: 'Ocorreu um erro a carregar os dados, por favor tente novamente.',
                });
            }
        });
    }

    function onFetchCountries() {
        setLoadingCountries(true);
        _service({
            method: 'GET',
            url: 'country/list',
            success: (response) => {
                setLoadingCountries(false);
                if (response.json) {
                    const list = response.json;
                    setCountries(list);
                    onFetchTimeZones();
                }
            },
            fail: (e) => {
                setLoadingCountries(false);
                setLoading(false);
                console.error(`Countries`, e);
                notification["error"]({
                    message: 'Falha nos Países',
                    description: 'Ocorreu um erro a carregar os países, por favor tente novamente mais tarde ou entre em contato com o suporte.',
                });
            }
        });
    }

    function onFetchTimeZones() {
        setLoading(true);
        _service({
            method: 'GET',
            url: 'timezone/list',
            success: (response) => {
                setLoadingTimeZones(false);
                setLoading(false);
                if (response.json) {
                    const list = response.json;
                    setTimeZones(list);
                }
            },
            fail: (e) => {
                setLoadingTimeZones(false);
                setLoading(false);
                console.error(`TimeZones`, e);
                notification["error"]({
                    message: 'Falha nos Fusos Horários',
                    description: 'Ocorreu um erro a carregar os fusos horários, por favor tente novamente mais tarde ou entre em contato com o suporte.',
                });
            }
        });
    }

    function onFinish(values) {
        setSubmitting(true);
        const data = {
          ...values,
          avatar: profileAvatar.current && profileAvatar.current.getImage()
        };
        const fail = () => {
            setSubmitting(false);
            setLoading(false);
            notification.error({
                message: "Falha ao Guardar os Dados",
                description: "Houve uma falha ao tentar guardar os dados, reporte a situação e tente mais tarde. Desculpe o transtorno.",
                top: 100,
                duration: 15
            });
        };

        data.socialNetworks.push({
          code: "linkedin",
          link: values.linkedin
        });

        _service({
            url: "people",
            method: 'POST',
            data,
            success: (response) => {
                if (response.json) {
                    setSubmitting(false);
                    setLoading(false);
                    notification.success({
                        message: "Perfil Atualizado",
                        description: "O seu perfil foi atualizado com sucesso.",
                        top: 100,
                        duration: 15
                    });
                    _service({
                      method: 'GET',
                      url: 'people/info',
                      success: (response) => {
                          if (response.json) {
                              Core.user = response.json;
                              loggedUserInfoAction(response.json);
                          }
                      }
                    });
                } else {
                    fail();
                }

            },
            fail: (e) => {
                console.log("Service perfil/update error:", e);
                fail();
            }
        });
    }

    function onValuesChange(changedValues, allValues) {
        if (allValues.password && allValues.password.length > 0) {
            setPasswordRequired(true);
        } else {
            setPasswordRequired(false);
        }
    };

    function onFinishFailed(errorInfo) {
        console.log('Failed:', errorInfo);
        if (errorInfo && errorInfo.errorFields && errorInfo.errorFields.length > 0) {
            for (const errorField of errorInfo.errorFields) {
                for (const error of errorField.errors) {
                    message.error(error);
                }
            }
        }
    }

    function onCountryChange(value) {
      const newData = data;
      newData.localization.timezone = "";
      newData.localization.country = value;
      form.setFieldsValue(newData);
      setTimeZoneRequired(true);
      setData(newData);
      setTimeZonesActive(
          timeZones.filter((timeZone) => timeZone.country_code == value)
      );
    }

    if (loading) {
        return (
          <Loading page />
        );
    } else {
        return (
            <div className="profile">
              <div className="content-title">
                <Back />
                <div>
                  <Title level={3}>Editar Perfil</Title>
                </div>
                <div>
                  <Avatar ref={profileAvatar} currentImage={avatarImageURL}/>
                </div>
              </div>
              <div className="content-body">
                <Form
                  {...layout}
                  onValuesChange={onValuesChange}
                  ref={profileForm}
                  layout="vertical"
                  name="basic"
                  initialValues={data}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  form={form}
                >
                  <h2>Dados Pessoais</h2>
                  <p>Complete e verifique os seus dados pessoais.</p>
                  <Row {...layout.rowGutter}>
                    <Col {...layout.spanCol}>
                      <Form.Item
                        label="Nome"
                        name="name"
                        rules={[
                            { required: true, message: 'Insira o seu nome.' },
                            { type: 'string', message: 'Nome inválido, apenas letras minúsculas e maiúsculas.', pattern: "^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$" }
                        ]}
                      >
                        <Input disabled={submitting} maxLength={25} />
                      </Form.Item>
                      <Form.Item
                        label="E-mail"
                        name="email"
                        rules={[
                            { type: 'email', message: 'O e-mail inserido não é válido.' },
                            { required: true, message: 'Insira o e-mail.' }
                        ]}
                      >
                        <Input disabled={submitting} maxLength={250} />
                      </Form.Item>
                      <Form.Item name={['people', 'phone']} label="Telefone no WhatsApp" rules={[{ required: true, message: "Insira o número do Whatsapp." }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Perfil no Linkedin"
                        name="linkedin"
                        rules={[
                          { required: true, message: "Insira o seu Linkedin."}
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col {...layout.spanCol}>
                      <Form.Item
                        label="Nome de usuário"
                        name="username"
                        rules={[
                            { required: true, message: 'Insira o seu nome.' },
                            { type: 'string', message: 'Nome inválido, apenas letras minúsculas e maiúsculas.', pattern: "^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$" }
                        ]}
                      >
                        <Input disabled={submitting} maxLength={25} />
                      </Form.Item>
                      <Form.Item
                        label="Nova Senha"
                        name="password"
                        rules={[
                            { type: 'string', message: 'Senha deverá ter entre 8 a 25 caracteres.', min: 8, max: 25 },
                        ]}
                      >
                        <PasswordInput autoComplete="new-password"/>
                      </Form.Item>
                      <Form.Item
                        label="Confirmar nova Senha"
                        name="password_confirm"
                        rules={[
                            { required: passwordRequired, message: 'Insira a confirmação da nova senha.' },
                            { type: 'string', message: 'Senha deverá ter entre 8 a 25 caracteres.', min: 8, max: 25 },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('As palavras-passes não são iguais.');
                                },
                            })
                        ]}
                      >
                        <Input.Password maxLength={25} />
                      </Form.Item>
                      <Form.Item name={['people', 'github']} label="Perfil no GitHub" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider/>
                  <h2>Localização</h2>
                  <p>Configure a sua localização para obter corretamente os horários das atividades.</p>
                  <Row {...layout.rowGutter}>
                    <Col {...layout.spanCol}>
                      <Form.Item name={['localization', 'country']} label="País" rules={[{ required: false }]}>
                        <Select onChange={onCountryChange}>
                          { countries.map((country) => <Select.Option value={country.code}>{country.name}</Select.Option>)}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col {...layout.spanCol}>
                      <Form.Item name={['localization', 'timezone']} label="Fuso Horário" rules={[{ required: timeZoneRequired }]}>
                        <Select disabled={ timeZonesActive.length == 0 }>
                          { timeZonesActive.map((timeZone) => <Select.Option value={timeZone.code}>{timeZone.name}</Select.Option>)}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider/>
                  <h2>Empresa Atual</h2>
                  <p>Caso trabalhe em alguma empresa por favor preencha os campos abaixo.</p>
                  <Row {...layout.rowGutter}>
                    <Col {...layout.spanCol}>
                      <Form.Item name={['company', 'website']} label="Website" rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col {...layout.spanCol}>
                      <Form.Item name={['company', 'employees']} label="Total de Colaboradores" rules={[{ required: false }]}>
                        <InputNumber min={0}/>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                  <h2>Redes Sociais</h2>
                  <Form.List name={["socialNetworks"]}>
                    {(fields, { add, remove }) => {
                        return (
                            <div>
                              {fields.map(field => (
                                  <Row className="social-network-line" {...layout.rowGutter}>
                                    <Col sm={24} md={7}>
                                      <Form.Item
                                        // {...field}
                                        name={[field.name, 'code']}
                                        fieldKey={[field.fieldKey, 'code']}
                                        label="Rede Social"
                                        rules={[{ required: true }]}>
                                        <Select defaultValue="Selecione">
                                          {/* <Select.Option value="linkedin">LinkedIn</Select.Option> */}
                                          <Select.Option value="instagram">Instagram</Select.Option>
                                          <Select.Option value="facebook">Facebook</Select.Option>
                                          <Select.Option value="twitter">Twitter</Select.Option>
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    <Col sm={24} md={14}>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, 'link']}
                                        fieldKey={[field.fieldKey, 'link']}
                                        label="Link"
                                        rules={[{ required: true }]}>
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col sm={24} md={3}>
                                      <MinusCircleOutlined twoToneColor="#ff4d4f" onClick={() => { remove(field.name); }} />
                                    </Col>
                                  </Row>
                              ))}
                              <Form.Item>
                                <Button type="dashed" onClick={() => { add(); }} block >
                                  <PlusOutlined /> Adicionar
                                </Button>
                              </Form.Item>
                            </div>
                        );
                    }}
                  </Form.List>
                  <Divider />
                  <h2>Principais Habilidades</h2>
                  <Form.Item name={['people', 'experience']} label="Anos de Experiência Técnica" rules={[{ required: false }]}>
                    <InputNumber min={0}/>
                  </Form.Item>
                  <Row {...layout.rowGutter}>
                    {[
                        {code: 'nodejs', name: 'NodeJS' },
                        {code: 'sql', name: 'SQL' },
                        {code: 'linux', name: 'Linux' },
                        {code: 'reactjs', name: 'ReactJS' },
                        {code: 'frontend', name: 'Frontend' },
                        {code: 'backend', name: 'Backend' },
                        {code: 'code', name: 'Programação' },
                        {code: 'design', name: 'Design' },
                        {code: 'db', name: 'Base de Dados' },
                        {code: 'excel', name: 'Excel' },
                        {code: 'pdf', name: 'PDF' },
                        {code: 'js', name: 'JavaScript' },
                        {code: 'py', name: 'Python' },
                        {code: 'rb', name: 'Ruby' },
                        {code: 'kt', name: 'Kotlin' },
                        {code: 'java', name: 'Java' },
                        {code: 'php', name: 'PHP' },
                        {code: 'wp', name: 'WordPress' },
                        {code: 'laravel', name: 'Laravel' },
                        {code: 'mysql', name: 'MySQL' },
                        {code: 'psql', name: 'PostgreSQL' },
                        {code: 'ml', name: 'Machine Learning' },
                        {code: 'bigdata', name: 'Big Data' },
                        {code: 'rest', name: 'RestAPI' },
                        {code: 'svg', name: 'SVG/AI' },
                        {code: 'ubuntu', name: 'Ubuntu' },
                        {code: 'cs', name: '.Net/C#' },
                        {code: 'mq', name: 'Message Queue' }
                    ].map(field => (
                        <Col {... { xs: { span: 12 }, sm: { span: 8 }, md: { span: 8 }, lg: { span: 6 } }}>
                          <Form.Item
                            valuePropName="checked"
                            name={["skills", field.code]}
                            label={field.name}>
                            <Switch />
                          </Form.Item>
                        </Col>
                    ))}
                  </Row>
                  <Divider />
                  <Form.Item style={{marginTop: '50px', marginBottom: '50px'}}>
                    <Button type="primary" size="large" htmlType="submit" loading={submitting}>
                      Alterar Perfil
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    const { loggedUserInfo, loggedUserInfoReload } = store.loggedUserInfoState;
    return {
        loggedUserInfo, loggedUserInfoReload
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    loggedUserInfoAction
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
