import { useEffect, useState } from "react";
import { notification, Tabs } from "antd";
import { SoundOutlined, TrophyOutlined } from "@ant-design/icons";
import _service from '@netuno/service-client';
import HistoryRecords from "./Records";

import './index.less';

function HistoryTabs({ user, initialTab }) {
    const [code, setCode] = useState(initialTab || "");
    const [eventsRecords, setEventsRecords] = useState({
        list: [],
        loading: false
    });
    const [formationsRecords, setFormationsRecords] = useState({
        list: [],
        loading: false
    });
    const [eventsPuntuaction, setEventsPuntuaction] = useState(0);
    const [formationsPuntuaction, setFormationsPuntuaction] = useState(0);

    const onTabChange = (key) => {
        setCode(key);
    };

    const fetchRecords = (code) => {
        if (code === "events") {
            setEventsRecords({
                list: [],
                loading: true
            });
        }
        if (code === "formations") {
            setFormationsRecords({
                list: [],
                loading: true
            });
        }

        const data = {}

        if (user) {
            data.user = user;
        }

        _service({
            url: `/history/${code}`,
            data,
            success: (response) => {
                if (response.json) {
                    if (code === "events") {
                        setEventsRecords({
                            list: response.json,
                            loading: false
                        });
                    }
                    if (code === "formations") {
                        setFormationsRecords({
                            list: response.json,
                            loading: false
                        });
                    }
                }
            },
            fail: (e) => {
                if (code === "events") {
                    setEventsRecords({
                        list: [],
                        loading: false
                    });
                }
                if (code === "formations") {
                    setFormationsRecords({
                        list: [],
                        loading: false
                    });
                }

                notification.error({
                    message: "Falha ao carregar informações"
                });
                console.error("History Records Service Error", e);
            }
        });
    };

    useEffect(() => {
        fetchRecords("events");
        fetchRecords("formations");
    }, []);

    useEffect(() => {
        const totalEventsPuntuaction = eventsRecords.list.reduce((total, event) => {
            return total + parseInt(event.puntuaction);
        }, 0);
        setEventsPuntuaction(totalEventsPuntuaction);
    }, [eventsRecords.list]);

    useEffect(() => {
        const totalFormationsPuntuaction = formationsRecords.list.reduce((total, formation) => {
            const puntuaction = parseInt(formation.puntuaction);
            return isNaN(puntuaction) ? total : total + puntuaction;
        }, 0);
        setFormationsPuntuaction(totalFormationsPuntuaction);
    }, [formationsRecords.list]);

    return (
        <Tabs
            className="history__tabs"
            activeKey={code}
            onChange={onTabChange}
            items={[
                {
                    key: "events",
                    label: <><SoundOutlined className="anticon" />Eventos </>,
                    children:
                        <>
                            <p className="total-score">Pontuação total: {eventsPuntuaction}</p>
                            <p>Total de Eventos: {eventsRecords.list.length}</p>
                            <HistoryRecords
                                code="events"
                                user={user}
                                list={eventsRecords.list}
                                loading={eventsRecords.loading}
                            />
                        </>
                },
                {
                    key: "formations",
                    label: <><TrophyOutlined className="anticon" />Formações </>,
                    children:
                        <>
                            <p className="total-score">Pontuação total: {formationsPuntuaction}</p>
                            <p>Total de Formações: {formationsRecords.list.length}</p>
                            <HistoryRecords
                                code="formations"
                                user={user}
                                list={formationsRecords.list}
                                loading={formationsRecords.loading}
                            />
                        </>
                },
            ]}
        />
    );
}

export default HistoryTabs;