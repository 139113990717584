import React, { useEffect, useState } from 'react';

import { Row, Col, Typography, Divider, Button, Form, Result, Input, notification, Alert } from 'antd';

import { CloseCircleFilled, StarFilled, CheckCircleFilled } from '@ant-design/icons';

import _service from '@netuno/service-client';

import { json, useParams } from "react-router-dom";

import Loading from '../../../components/Loading';

import './index.less';

const { Title, Text } = Typography;

function Challenge({ props }) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [note, setNote] = useState(false);
    const [responseNotification, setResponseNotification] = useState(false);
    const [expiredChallenge, setExpiredChallenge] = useState(false);
    const [missedDeadline, setMissedDeadline] = useState(false);
    const [componentDisabled, setComponentDisabled] = useState(false);
    const { uid } = useParams();
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0)

    useEffect(() => {
        _service({
            url: "/formation/challenge",
            data: { uid },
            success: (response) => {
                if (response.json) {
                    setData(response.json);
                    setLoading(false);
                    if (response.json.concluded) {
                        setSuccess(true)
                        setResponseNotification(true)
                    }
                    if (response.json.note) {
                        setNote(true)
                    }
                    const endDate = new Date(response.json.end_date + 'T23:59:00Z'); // Adiciona a hora e define o fuso horário como UTC
                    if (currentDate  > endDate) {
                        // periodo de entrega do desafio expirou
                        setExpiredChallenge(true)
                        if (props.registered && !response.json.concluded) {
                            // perdeu prazo de envio do desafio
                            setMissedDeadline(true)
                            setComponentDisabled(true)
                        }
                    }
                }
            },
            fail: (e) => {
                notification["error"]({
                    message: 'Detalhes da Formação',
                    description: 'Não foi possível carregar os detalhes do desafio, tente mais tarde ou entre em contato com o suporte.',
                });

            }
        });

    }, [success]);

    const result = () => {
        if (success) {
            return (
                <Result
                    status="success"
                    subTitle="Recebemos sua resposta com sucesso. Aguarde nosso retorno."
                    className="fade-out"
                    icon={<a
                        className="success-icon"
                    >
                        <CheckCircleFilled />
                    </a>}
                />
            );
        } else {
            return (
                <Result
                    status="error"
                    subTitle="Foi gerado um erro no envio e não recebemos a sua resposta, por favor entre em contato com o suporte."
                    icon={<a
                        className="error-icon"
                    >
                        <CloseCircleFilled />
                    </a>}
                />
            );
        }
    };

    function onFinish(values) {
        setSubmitting(true)
        const data = {
            ...values,
            uid
        };
        _service({
            url: "/formation/challenge",
            method: 'POST',
            data,
            success: (response) => {
                if (response.json) {
                    notification.success({
                        message: responseNotification ? "Resposta Atualizada" : "Resposta enviada",
                        description: responseNotification ? "A sua resposta foi atualizada com sucesso." : "A sua resposta foi enviada com sucesso.",
                    });
                    setSubmitting(false)
                    setSuccess(true)
                }
            },
            fail: (e) => {
                notification.error({
                    message: responseNotification ? "Falha ao guardar a resposta" : "Falha ao guardar a resposta",
                    description: responseNotification ? "Houve uma falha ao tentar atualizar a resposta, reporte a situação e tente mais tarde. Desculpe o transtorno." : "Houve uma falha ao tentar enviar a resposta, reporte a situação e tente mais tarde. Desculpe o transtorno.",
                });
                setSuccess(false)
                setSubmitting(false)
            }
        });
    }

    useEffect(() => {
        if (props.open === true && !props.registered) {
            setComponentDisabled(true);
        }
    }, [props.open, props.registered]);

    if (loading) {
        return <Loading />;
    }

    if (props.open === false && !props.registered) {
        return (
            <>
                <Row>
                    <Col span={24}><Title level={3}>Desafio</Title></Col>
                    <Col span={24}>
                        <p>Data limite de entrega: {data.end_date} </p>
                        <p dangerouslySetInnerHTML={{ __html: data.description }} />
                    </Col>
                    <Col span={24} style={{ marginTop: 10 }}>
                        <Alert className='alert-challenge' message="Notamos que você não participou desta formação, portanto, o envio da resposta não está disponível." type="info" showIcon />
                    </Col>
                </Row>
                <Divider />
            </>
        );
    }

    const
        tips = (
            <>
                Pode utilizar o  <a href='https://github.com/' target="_blank">GitHub</a> (https://github.com/) ou então o  <a target="_blank" href='https://git.academy.netuno.org/'>Git da Academia do Netuno</a> (https://git.academy.netuno.org/).
            </>
        );

    return (
        <>
            <Row>
                <Col span={24}><Title level={3}>Desafio</Title></Col>
                <Col span={24}>
                    <p>Data limite de entrega: {data.end_date} </p>
                    <p dangerouslySetInnerHTML={{ __html: data.description }} />
                </Col>
                <Col span={24} style={{ marginTop: 10 }}>
                    {!componentDisabled && <Form
                        name="basic"
                        layout="horizontal"
                        className='form-challenge'
                        style={{
                            maxWidth: 600,
                        }}
                        initialValues={data}
                        onFinish={onFinish}
                        autoComplete="off"
                        disabled={componentDisabled}
                    >
                        <Form.Item
                            label="Link do Git:"
                            name="link"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor digite o link!',
                                },
                            ]}

                            help={!expiredChallenge && tips}
                        >
                            {missedDeadline ? (<><Input /> </>) : (
                                expiredChallenge ? <p style={{ marginBottom: 0 }}>{data.link}</p> : <Input />)}
                        </Form.Item>
                        <Form.Item
                            label="Comentários:"
                            name="comment"
                        >
                            {missedDeadline ? (<Input.TextArea />) : (
                                expiredChallenge ? <p style={{ marginBottom: 0 }}>{data.comment}</p> : <Input.TextArea rows={6} />)}
                        </Form.Item>
                        {!note && !expiredChallenge ? (
                            <Form.Item>
                                {responseNotification && success ? (
                                    <Button type="primary" htmlType="submit" loading={submitting}>
                                        Editar Resposta
                                    </Button>
                                ) : (
                                    <Button type="primary" htmlType="submit" loading={submitting}>
                                        Enviar
                                    </Button>
                                )}
                            </Form.Item>
                        ) : (
                            !success && (
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={submitting}>
                                        Enviar
                                    </Button>
                                </Form.Item>
                            )
                        )}

                    </Form>}
                    {missedDeadline && <Alert  className='alert-challenge' message="O prazo foi encerrado. Não é possível o envio da resposta." type="error" showIcon />}
                    {note ?
                        (
                            <>
                                <p><StarFilled />Nota: {data.note}</p>
                                <p dangerouslySetInnerHTML={{ __html: data.note_comment }}></p>
                            </>
                        ) : null}
                    {expiredChallenge && !note && responseNotification && result()}
                </Col>
            </Row>
            <Divider />
        </>
    );
}

export default Challenge;
