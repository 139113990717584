import React, { useEffect, useState } from "react";

import { Typography, Button, Spin, Alert, notification } from "antd";

import _service from "@netuno/service-client";

import "./index.less";

const { Title } = Typography;

export default ({ data }) => {
  const [registered, setRegistered] = useState(data.registered);
  const [loading, setLoading] = useState(false);
  const servicePrefix = _service.config().prefix;
  const onRegister = () => {
    setLoading(true);
    _service({
      method: "PUT",
      url: "/formation",
      data: { uid: data.uid },
      success: (response) => {
        if (response.json && response.json.result) {
          setRegistered(true);
          setLoading(false);
        }
      },
      fail: (e) => {
        console.error("Formation Service Error", e);
        notification["error"]({
          message: "Fazer a Inscrição",
          description:
            "Não foi possível se inscrever na formação, tente mais tarde ou entre em contato com o suporte.",
        });
      },
    });
  };
  const onCancel = () => {
    setLoading(true);
    _service({
      method: "DELETE",
      url: "/formation",
      data: { uid: data.uid },
      success: (response) => {
        if (response.json && response.json.result) {
          setRegistered(false);
          setLoading(false);
        }
      },
      fail: (e) => {
        console.error("Formation Service Error", e);
        notification["error"]({
          message: "Cancelar a Inscrição",
          description:
            "Não foi possível cancelar a inscrição na formação, tente mais tarde ou entre em contato com o suporte.",
        });
      },
    });
  };

  if (data.open == false) {
    return (
      <div>
        {!registered && (
          <>
            <Alert
              message={
                <>
                  <Title className="ant-alert-description" level={5}>
                    Você não participou desta formação.
                  </Title>
                </>
              }
              type="error"
              description="Aproveite e inscreva-se já em outra formação que irá decorrer."
            />
          </>
        )}
        {registered && (
          <>
            <Alert
              message={
                <>
                  <Title level={5} className="ant-alert-description">
                    Inscrição realizada nesta formação.
                  </Title>
                </>
              }
              type="success"
              description="Aproveite e inscreva-se já em outra formação que irá decorrer."
            />
          </>
        )}
      </div>
    );
  }

  return (
    <div>
      {loading && <Spin />}
      {!loading && !registered && (
        <>
          <Alert
            message={
              <>
                {data.started ? (
                  <Title level={5}>
                    Já está a decorrer mas ainda pode participar desta formação:{" "}
                  </Title>
                ) : (
                  <Title level={5}>
                    Garanta já o seu lugar nesta formação:{" "}
                  </Title>
                )}
              </>
            }
            type="info"
            action={
              <>
                <Button
                  className="btn-register"
                  size="large"
                  type="primary"
                  onClick={onRegister}
                >
                  Participar
                </Button>
              </>
            }
            description="É totalmente gratuita e as vagas são limitadas."
          />
        </>
      )}
      {!loading && registered && (
        <>
          <Alert
            message={
              <>
                <Title level={5} className="ant-alert-description">
                  Inscrição realizada nesta formação.
                </Title>
              </>
            }
            type="success"
            action={
              <>
                {!data.started && (
                  <Button
                    size="large"
                    className="btn-cancel"
                    type="danger"
                    onClick={onCancel}
                  >
                    Cancelar
                  </Button>
                )}
              </>
            }
            description={
              data.started
                ? "Aproveite e inscreva-se já em outra formação que irá decorrer."
                : "Caso não possa participar libere o seu lugar para outra pessoa."
            }
          />
        </>
      )}
    </div>
  );
};
