import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  Row,
  Col,
  Typography,
  Button,
  Spin,
  Alert,
  notification,
  Divider,
  List,
  Modal,
  Steps,
  Popover,
} from "antd";

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlayCircleOutlined,
  VideoCameraOutlined,
  ClockCircleOutlined,
  AlignCenterOutlined,
  EditOutlined
} from "@ant-design/icons";

import _service from "@netuno/service-client";
import moment from "moment-timezone";
import Core from "../../Core";

import { Link } from "react-router-dom";

import CalendarLinks from "../CalendarLinks";

import "./index.less";

import ParticipantsSessionList from '../../components/ParticipantsSessionList';


const { Title, Text } = Typography;

export default function SessionsList({ type, isHistory, data }) {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [timeZone, setTimeZone] = useState("");
  const [recordOpen, setRecordOpen] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    _service({
      url: `/history/${type}s/sessions`,
      data: {uid: data.uid},
      success: (response) => {
        if (response.json) {
          setLoading(false);
          setSessions(response.json);
        }
      },
      fail: (e) => {
        setLoading(false);
        console.error("History Records Sessions Service Error", e);
      },
    });
  }, []);

  if (loading) {
    return (
        <div className="sessions-list__loading"><Spin /></div>
    );
  }
  
  return (
    <div className="sessions-list">
      {recordOpen && (
        <Modal
          title={recordOpen.number + " - " + recordOpen.name}
          visible={true}
          footer={[]}
          onCancel={() => setRecordOpen(null)}
          width={"100vw"}
        >
          {recordOpen.record_embed && recordOpen.record_embed != "" ? (
            <div
              className="sessions-list__record__video"
              dangerouslySetInnerHTML={{
                __html: recordOpen.record_embed,
              }}
            ></div>
          ) : null}
          {recordOpen.record_link && recordOpen.record_link != "" ? (
            <div className="sessions-list__record__video__link">
              <a href={recordOpen.record_link} target="_blank">
                <VideoCameraOutlined /> Abrir Gravação
              </a>
            </div>
          ) : null}
          <p
            className="sessions-list__description"
            dangerouslySetInnerHTML={{ __html: recordOpen.description }}
          />
          <p className="sessions-list__date">
            {Core.toUserLocalDateTime(recordOpen.start_date_time)} até{" "}
            {Core.toUserLocalTime(recordOpen.end_date_time)}
          </p>
        </Modal>
      )}
      {sessions.length == 0 ? (
        <div className="sessions-list__empty">
          <Title level={3}>Sessões brevemente disponíveis.</Title>
          <p>A agenda de cada uma das sessões e os respectivos detalhes descritivos estão sendo preparados, enquanto isto aproveite e garanta já o seu lugar neste evento.</p>
          <Divider />
        </div>
      ) : (
        <>
          <Title level={3}>Sessões</Title>
          <Steps
            direction="vertical"
            items={sessions.map((session, index) => ({
              title: (
                <>
                  <Row
                    gutter={16}
                    justify="space-between">
                    <Col span={12} xs={{ span: 24 }} lg={{ span: 20 }}>
                      <Row>
                        <Title level={4}>
                          <Link to={`/${type}/session/${session.uid}`}>
                            {`${index + 1} - ${session.name}`}
                          </Link>
                        </Title>
                      </Row>
                      <Row>
                        <Col>
                          <p className="sessions-list__date">
                            {Core.toUserLocalDateTime(session.start_date_time)} até{" "}
                            {Core.toUserLocalTime(session.end_date_time)} &nbsp;
                            {!isHistory && session.scheduled && <CalendarLinks session={session} />}
                          </p>
                          <p className="sessions-list__description"
                            dangerouslySetInnerHTML={{ __html: session.description }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col className="col-btn-session">
                      <p>
                        <Button
                          className="btn-session"
                          icon={
                            session.scheduled ? null :
                            <Text type="success">
                              <PlayCircleOutlined />
                            </Text>
                          }
                          onClick={() => navigate(`/${type}/session/${session.uid}`)
                                  }
                        >
                          {session.scheduled ? null : <>&nbsp;</>}Detalhe
                        </Button>
                      </p>
                      <ParticipantsSessionList participants={session.participants} />
                    </Col>
                  </Row>
                </>
              ),
              description: (
                <>
                  <Divider />
                </>
              ),
              icon: session.scheduled ? (
                <Text type="warning"  >
                  <Popover title="Brevemente">
                    <ClockCircleOutlined style={{ fontSize: '200%' }} />
                  </Popover>
                </Text>
              ) : (
                session.attended > 0 ? (
                  <Text type="success" >
                    <Popover title="Presente"  >
                      <CheckCircleOutlined style={{ fontSize: '200%' }} />
                    </Popover>
                  </Text>
                ) : (
                  <Text type="danger">
                    <Popover title="Faltou">
                      <CloseCircleOutlined style={{ fontSize: '200%' }} />
                    </Popover>
                  </Text>
                )
              ),
            }))}
          />
        </>
      )}
    </div>
  );
}
