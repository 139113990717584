import { Col } from "antd";
import { useState } from "react";
import "./index.less";

function CardInfor() {
  const [content] = useState([
    {
      title: `Mentoria`,
      description: `Acesso a sessões individuais para o aconselhamento de carreira.`,
    },
    {
      title: `Soft-skills`,
      description: `Dicas para pensar e agir como um programador.`,
    },
    {
      title: `Projetos Open Source`,
      description: `Participação prática no desenvolvimento de projetos comunitários
        no GitHub.`,
    },
    {
      title: `Ambiente Remoto`,
      description: `Containers com alto desempenho e disponíveis durante a formação.`,
    },
    {
      title: `Aulas Gravadas`,
      description: `Todos os materiais disponíveis online.`,
    },
    {
      title: `Eventos`,
      description: `Aumente seu network, obtenha conteúdos exclusivos e participe de
                    comunidades.`,
    },
    {
      title: `Formações`,
      description: `100% gratuita. Aprenda na prática e desenvolva aplicações reais.`,
    },
    {
      title: `Suporte`,
      description: `Esclareça as dúvidas técnicas em nosso fórum e canais exclusivos.`,
    },
  ]);
  return (
    <>
      {content.map((item, index) => (
          <Col xl={6} md={12} sm={12} xs={24}>
            <div className="bootcamp__container--cardgroup--card">
              <p className="bootcamp__container--cardgroup--title" key={index}>
                {item.title}
              </p>
              <p className="bootcamp__container--cardgroup--description">
                {item.description}
              </p>
            </div>
          </Col>
        )
      )}
    </>
  );
}

export default CardInfor;
