// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/antd/dist/reset.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".teste {\n  text-align: center;\n}\n.foto {\n  height: 25%;\n  width: 25%;\n}\n.introduction-grid {\n  display: grid;\n  grid-template-columns: 32% 32% 32%;\n  padding: 30px 40px 60px 40px !important;\n  gap: 24px;\n  justify-content: space-between;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Introduction/index.less"],"names":[],"mappings":"AACA;EACI,kBAAA;AACJ;AACA;EACI,WAAA;EACA,UAAA;AACJ;AAEA;EACI,aAAA;EACA,kCAAA;EACA,uCAAA;EACA,SAAA;EACA,8BAAA;AAAJ","sourcesContent":["@import '../../styles/variables.less';\n.teste{\n    text-align: center;\n}\n.foto{\n    height: 25%;\n    width: 25%;\n}\n\n.introduction-grid {\n    display: grid;\n    grid-template-columns: 32% 32% 32%;\n    padding: 30px 40px 60px 40px !important;\n    gap: 24px;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
