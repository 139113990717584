import React from 'react';

import { Spin } from "antd";

import classNames from "classnames";

import "./index.less";

function Loading({children, page}) {
    return (
      <div className={
        "loading"
          + classNames({ " loading__page": page })
      }>
        <Spin size="large"/>
        {children}
      </div>
    );
    }

export default Loading;
