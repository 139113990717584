// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/antd/dist/reset.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ant-alert {\n  margin-top: 0px !important;\n  padding: 0px !important;\n  margin-bottom: 16px;\n  background-color: transparent;\n  border: none;\n  flex-direction: column;\n}\n@media screen and (max-width: 768px) {\n  .ant-alert {\n    display: flex ;\n    flex-direction: column;\n  }\n  .ant-alert .ant-alert-action {\n    margin-left: 0;\n    margin-top: 12px;\n    width: 100%;\n  }\n  .ant-alert .ant-alert-action button {\n    width: 100% !important;\n  }\n}\n.btn-register {\n  margin-top: 16px !important;\n}\n.btn-cancel {\n  margin-top: 16px !important;\n  background-color: #dc4446 !important;\n  padding: 5.5px 15px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/FormationRegister/index.less"],"names":[],"mappings":"AAEA;EACI,0BAAA;EACA,uBAAA;EACA,mBAAA;EACA,6BAAA;EACA,YAAA;EACA,sBAAA;AAAJ;AAEI;EAAA;IACI,cAAA;IACA,sBAAA;EACN;EAHE;IAKQ,cAAA;IACA,gBAAA;IACA,WAAA;EACV;EARE;IAUY,sBAAA;EACd;AACF;AAQA;EACI,2BAAA;AANJ;AASA;EACI,2BAAA;EACA,oCAAA;EACA,mBAAA;AAPJ","sourcesContent":["@import '../../styles/variables.less';\n\n.ant-alert{\n    margin-top: 0px !important;\n    padding: 0px !important;\n    margin-bottom: 16px;\n    background-color: transparent;\n    border: none;\n    flex-direction: column;\n\n    @media screen and (max-width: @mobile-width) {\n        display: flex ;\n        flex-direction: column;\n\n        .ant-alert-action{\n            margin-left: 0;\n            margin-top: 12px;\n            width: 100%;\n\n            button{\n                width: 100% !important;\n            }\n        }\n      }\n}\n\n// .ant-alert-description {\n//     font-size: 17px;\n//   }\n\n.btn-register {\n    margin-top: 16px !important;\n}\n\n.btn-cancel {\n    margin-top: 16px !important;\n    background-color: #dc4446 !important;\n    padding: 5.5px 15px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
