import React, {useEffect, useState} from 'react';

import { Spin, Typography } from 'antd';

import _auth from '@netuno/auth-client';
import _service from '@netuno/service-client';

import HistoryItem from './Item';

import './index.less';

const { Title } = Typography;

export default function HistoryRecords({code, user = "", list, loading}) {
    

    return (
        <div className="history__records">
          { loading && 
            <div className="history__records__loading">
              <Title level={5}><Spin /></Title>
            </div> }
                { list.map((data)=> {
                    data.user = user;
                    return <HistoryItem key={data.uid} code={code} data={data}/>;
                }) }
        </div>
    );
}
