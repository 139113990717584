import React, { useEffect, useState } from 'react';

import { Spin, Typography, Tag, Row, Col, Button, notification } from 'antd';

import { TrophyOutlined, CheckCircleOutlined, CloseCircleOutlined, ClockCircleOutlined, SignalFilled, BranchesOutlined } from '@ant-design/icons';

import {
    FaWhatsapp
} from "react-icons/fa";

import _service from '@netuno/service-client';

import { useParams } from "react-router-dom";

import Back from '../../components/Back';
import FormationRegister from '../../components/FormationRegister';
import SessionsList from '../../components/SessionsList';
import Loading from '../../components/Loading';

import './index.less';
import ParticipantsList from '../../components/ParticipantsList';
import PracticeAccess from './PracticeAccess';
import Challenge from './Challenge';

const { Title } = Typography;

export default function Formation() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const { uid } = useParams();
    useEffect(() => {
        _service({
            url: "/formation",
            data: { uid },
            success: (response) => {
                if (response.json) {
                    setData(response.json);
                    setLoading(false);
                }
            },
            fail: (e) => {
                console.error("Formation Service Error", e);
                notification["error"]({
                    message: 'Detalhes da Formação',
                    description: 'Não foi possível carregar os detalhes da formação, tente mais tarde ou entre em contato com o suporte.',
                });
            }
        });
    }, []);

  const servicePrefix = _service.config().prefix;

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="formation">
      <Back to="/formations" />
      <Row gutter={20} justify="space-around" align="middle">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <div
            className="formation__cover"
            style={{
              backgroundImage: `url(/images/formation/${data.imagem})`
            }}>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Title className="formation__title" level={3}>{data.name}</Title>
          <FormationRegister data={data} />
          <p className="formation__date">De {data.start_date} até {data.end_date}.</p>
          { data.whatsapp && data.whatsapp != '' ? <p><a href={data.whatsapp} target="_blank"><FaWhatsapp /> Grupo no WhatsApp</a> exclusivo para esta formação, participe.</p> : null }
          <p>
            <Tag className="tag__formation"><TrophyOutlined />Formações</Tag>
            <Tag className="tag__level"><SignalFilled />{data.difficulty}</Tag>
            {data.duration ? (
              <Tag className="tag__duration"><ClockCircleOutlined />{data.duration} horas</Tag>
            ) : (<Tag className="tag__duration"><ClockCircleOutlined />- horas</Tag>)}
          </p>
          {
            data?.participants?.length > 0 && <ParticipantsList participants={data.participants} />
          }
        </Col>
      </Row>
      {data.available && <Challenge props={data} />}
      {data.practice && <PracticeAccess data={data} />}
      {data.git && data.git != '' && <p className="formation__git"><Button type="primary" icon={<BranchesOutlined />} onClick={() => window.open(data.git)}>Repositório do Código Fonte</Button></p>}
      <Row gutter={20} justify="center" align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <p className="formation__description" 
             dangerouslySetInnerHTML={{ __html: data.description }} 
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <p className="formation__description">
            Esclareça suas dúvidas no{" "} 
            <a href="https://forum.netuno.org" target="_blank">
              Fórum{" "}
            </a>
            e no nosso servidor do{" "} 
            <a href="https://discord.com/invite/4sfXG6YWFu" target="_blank">
              Discord.
            </a>
          </p>
        </Col>
      </Row>
      <SessionsList type="formation" data={data} />
      <Back to="/formations" />
    </div>
  );
}
