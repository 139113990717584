import React, { useEffect, useState } from 'react';

import { Row, Col, Typography, Divider, Button, Collapse, Tag } from 'antd';

import { SoundOutlined, TrophyOutlined } from "@ant-design/icons";

import {
  FaGit,
} from "react-icons/fa";

import _service from '@netuno/service-client';

import { Link } from "react-router-dom";

import SessionsList from '../../../SessionsList';

import './index.less';

const { Title } = Typography;
const { Panel } = Collapse;

export default function HistoryItems({ code, data }) {
  const [showSessions, setShowSessions] = useState(false);
  const { name, description, start_date, end_date, git, sessions_total, sessions_attended } = data;
  const servicePrefix = _service.config().prefix;
  return (
    <div className="history__records__item">
      <Collapse>
        <Panel header={name} extra={<span className="history__records__item__date">de {data.start_date} até {data.end_date}</span>}>
          <Row gutter={20} justify="space-around" align="middle">
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>

              <div className="history__records__item__cover" style={{
                backgroundImage: `url(${servicePrefix}/${code == 'events' ? 'event' : 'formation'}/image?uid=${data.uid})`
              }}></div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Title className="history__records__item__title" level={2}>{data.name}</Title>
              <p className="history__records__item__date">De {data.start_date} até {data.end_date}.</p>
              <div className="history__records__item__description" dangerouslySetInnerHTML={{ __html: data.description }} />
              {data.certificate && data.certificate != '' ? <p><Link to={`/certificate/${data.certificate}`}>Certificado</Link></p> : null}
              <p>{code == "events" ? (
                <Tag color="cyan">
                  <SoundOutlined /> Evento
                </Tag>
              ) : (
                <Tag color="lime">
                  <TrophyOutlined /> Formação
                </Tag>
              )}</p>
              {data.puntuaction > 0 && (
                <p>
                  Pontuação: {data.puntuaction}
                </p>
              )}
            </Col>
          </Row>
          {git && git != '' && <p className="history__records__item__git"><a href={data.git} target="_blank"><FaGit /><br />Repositório do Código Fonte</a></p>}
          <SessionsList type={code == 'events' ? 'event' : 'formation'} data={data} isHistory />
        </Panel>
      </Collapse>
      {/* <Row gutter={20} justify="space-around" align="middle">
            <Col xs={12} sm={8} md={12} lg={8}>
              <Link to={`/${code == 'events' ? 'event' : 'formation'}/${data.uid}`}><img src={`${servicePrefix}/${code == 'events' ? 'event' : 'formation'}/image?uid=${ data.uid }`}/></Link>
            </Col>
            <Col xs={12} sm={16} md={12} lg={16}>
              <Title level={4}>{name}</Title>
              <p>De {data.start_date} até {data.end_date}</p>
              <p dangerouslySetInnerHTML={{__html: data.description}}/>
              <p>Participou de {sessions_attended} sessões do total de {sessions_total} sessões.</p>
              <Button onClick={ () => setShowSessions(!showSessions) }>{ showSessions ? 'Fechar Sessões' : 'Ver Sessões' }</Button>
            </Col>
          </Row>
          {showSessions && <Sessions code={code} data={data}/> }
          <Divider/> */}
    </div>
  );
}
