import React, { useState, useEffect, useRef } from "react";
import { Layout } from "antd";
import Typed from "react-typed";
import _auth from "@netuno/auth-client";
import Techs from "./Techs";
import "./index.less";

const { Sider } = Layout;

export default function SiderHome(props) {
  return (
    <Sider
      width="50%"
      className="sider"
    >
      <div className="cover"></div>
      <p className="typed">
        <Typed
          strings={[
            "A plataforma Netuno lança você mais longe.",
            "Rumo ao full stack!",
            "Gratuito e open-source.",
          ]}
          loop
          typeSpeed={60}
        />
      </p>
      <Techs />
    </Sider>
  );
}
