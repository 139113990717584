import React, { useState, useEffect } from "react";

import {
  Layout,
  Typography
} from "antd";

import { Link, useParams } from "react-router-dom";

import _service from '@netuno/service-client';
import _auth from '@netuno/auth-client';

import "./index.less";

const { Title } = Typography;
const { Content, Sider } = Layout;

export default function Certificate(props) {
  const { code } = useParams();
  const servicePrefix = _service.config().prefix;
  const certificateImageURL = `${servicePrefix}/certificate/image?code=${code}`;
  let oldTitle = document.title;
  let oldOGTitle = document.querySelector('meta[property~="og:title"]').content;
  let oldOGDescription = document.querySelector('meta[property~="og:description"]').content;
  let oldOGImage = document.querySelector('meta[property~="og:image"]').content;
  useEffect(() => {
    document.title = "Netuno - Certificado";
    document.querySelector('meta[property~="og:image"]').content = certificateImageURL;
    document.querySelector('meta[property~="og:title"]').content = "Netuno - Certificado";
    document.querySelector('meta[property~="og:description"]').content = oldOGDescription;
    return () => {
      document.title = oldTitle;
      document.querySelector('meta[property~="og:title"]').content = oldOGTitle;
      document.querySelector('meta[property~="og:description"]').content = oldOGDescription;
      document.querySelector('meta[property~="og:image"]').content = oldOGImage;
    };
  }, []);
  return (
    <Layout>
      <Content className="certificate-container">
        <div className="content-body">
          <img src={certificateImageURL}/>
          {
            !_auth.isLogged() &&
              <Link to="/login">Iniciar Sessão</Link>
          }
        </div>
      </Content>
    </Layout>
  );
}
