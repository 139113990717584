import React, { useEffect, useState } from "react";

import {
  Tooltip,
  Row,
  Col,
  Typography,
  Button,
  Spin,
  Alert,
  notification,
} from "antd";

import {
  InfoCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  ClockCircleOutlined,
  CloseSquareOutlined
} from "@ant-design/icons";

import _service from "@netuno/service-client";

import { Link, useNavigate } from "react-router-dom";

import FormationRegister from "../../../components/FormationRegister";

import "./index.less";

const { Title, Text } = Typography;

export default function FormationsItem({ data }) {
  const [registered, setRegistered] = useState(data.registered);
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState(false);
  const servicePrefix = _service.config().prefix;
  const navigate = useNavigate();

  // if (detail) {
  //   return <Redirect to={`/formation/${data.uid}`} />;
  // }
  return (
    <div
      className="formations__item"
      onClick={() => navigate(`/formation/${data.uid}`)}
    >
      <div className="formations__item_body">
        <div
          className="formations__item_cover"
          style={{
            backgroundImage: `url(/images/formation/${data.imagem})`,
          }}
        ></div>
        <div className="formations__item_status">
          {registered && (data.started || data.open) ? (
            <Text type="success">
              <CheckOutlined /> O seu lugar está garantido.
            </Text>
          ) : (
            data.started ? (
              <Text type="warning">
                <ClockCircleOutlined /> Está a decorrer mas pode participar.
              </Text>
            ) : (
              data.open ? (
                <Text type="warning">
                  <ClockCircleOutlined /> Garanta já o seu lugar.
                </Text>
              ) : (
                <Text type="danger">
                  <CloseSquareOutlined /> Concluída.
                </Text>
              )
            )
          )}
        </div>
        <p className="formations__item_date">
          De {data.start_date} até {data.end_date}
        </p>
      </div>
      <div className="formations__item_footer">
        <p level={5}>{data.name}</p>
        <Tooltip
          placement="topRight"
          title={
            <Typography.Paragraph
              className="tooltip-content"
              ellipsis={{ rows: 5, expandable: false }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: data.description.replace(/<\/?[^>]+(>|$)/g, "")
                }}
              ></div>
            </Typography.Paragraph>
          }
        >
          <span>
            <InfoCircleOutlined />
          </span>
        </Tooltip>
      </div>
      {/* <Row gutter={20} justify="space-around" align="middle">
            <Col xs={12} sm={8} md={12} lg={8}>
              <Link to={`/formation/${data.uid}`}><img src={`${servicePrefix}/formation/image?uid=${ data.uid }`}/></Link>
            </Col>
            <Col xs={12} sm={16} md={12} lg={16}>
              <Title level={4}><Link to={`/formation/${data.uid}`}>{data.name}</Link></Title>
              <p>{data.open ? <>Inícia em </> : <>Decorreu em</>} {data.start_date} até {data.end_date}</p>
              <p>{data.open ? data.registered ? <Text type="success"><CheckOutlined />O seu lugar está garantido.</Text> : <Text type="warning"><ClockCircleOutlined /> Garanta já o seu lugar.</Text> : data.registered ? <Text type="success"><CheckOutlined /> Foi inscrito nesta formação.</Text> : <Text type="danger"><CloseOutlined /> Não participou desta formação.</Text>}</p>
              <Link to={`/formation/${data.uid}`}>Mais detalhes...</Link>
            </Col>
          </Row> */}
    </div>
  );
}
