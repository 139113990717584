import React, {useEffect, useState} from 'react';
import { Navigate } from "react-router-dom";
import { Row, Col,Divider} from 'antd';


import _auth from '@netuno/auth-client';
import _service from '@netuno/service-client';



import './index.less';


export default function Introduction(props) {

    if (!_auth.isLogged()) {
        return <Navigate to="/login" replace />;
    }
    return (
        
        <div className="APP">
            <Divider> Introdução</Divider>
            <Row gutter={[6, 6]}>
                <Col span={12} style={{backgroundColor: 'white', color: 'blue'}}>Imagem</Col>
                <Col span={12} style={{backgroundColor: 'yellow', color: 'blue'}}>Texto</Col>
            </Row>

            <Divider>Instalação</Divider>
            <Row gutter={[6, 6]}>
                <Col span={12} style={{backgroundColor: 'white', color: 'blue'}}>Imagem</Col>
                <Col span={12} style={{backgroundColor: 'yellow', color: 'blue'}}>Texto</Col>
            </Row>
            <Divider>Demo</Divider>
            <Row gutter={[6, 6]}>
                <Col span={12} style={{backgroundColor: 'white', color: 'blue'}}>Imagem</Col>
                <Col span={12} style={{backgroundColor: 'yellow', color: 'blue'}}>Texto</Col>
            </Row>

            <Divider>Academia</Divider>
            <Row gutter={[6, 6]}>
                <Col span={12} style={{backgroundColor: 'white', color: 'blue'}}>Imagem</Col>
                <Col span={12} style={{backgroundColor: 'yellow', color: 'blue'}}>Texto</Col>
            </Row>
 
        </div>
    );
}
