import _service from '@netuno/service-client';
import _auth from '@netuno/auth-client';
import React, { useEffect, useState } from 'react';
import { Navigate, useParams, useNavigate, Link } from 'react-router-dom';
import Core from "../../Core";
import { Spin, Typography, notification, Button, Row, Col, Tabs } from 'antd';

import { ArrowLeftOutlined, AlignCenterOutlined, EditOutlined } from '@ant-design/icons';

import ParticipantsSessionList from "../../components/ParticipantsSessionList";

import EventRegister from "../../components/EventRegister";
import FormationRegister from "../../components/FormationRegister";
import CalendarLinks from "../../components/CalendarLinks";

import Material from "./Material";
import Exercise from "./Exercise";

import './index.less';

const { Title, Text } = Typography;

function Session({type}) {
    const [loading, setLoading] = useState(true);
    const [session, setSession] = useState();
    const { uid } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        _service({
            url: `/${type}/session`,
            data: {
                uid
            },
            success: (response) => {
                if (response.json) {
                    setSession(response.json);
                    setLoading(false);
                }
            },
            fail: (e) => {
                console.error("Session Service Error", e);
                notification["error"]({
                    message: 'Detalhe da Sessão',
                    description: 'Não foi possível carregar o detalhe da sessão, tente mais tarde ou entre em contato com o suporte.',
                });
            },
        });
    }, []);
    if (!_auth.isLogged()) {
        return <Navigate to="/login" replace />;
    }
    if (loading) {
        return (
          <div className="session">
            <div className="session-video__loading"><Spin /></div>
          </div>
        );
    }

    return (
        <>
            {session &&
                <div className="session">
                  <Button className="go-back-btn" type="link" onClick={() => navigate(-1)}><ArrowLeftOutlined /> Voltar</Button>
                  <Title level={3}><Link to={`/${type}/${session[type].uid}`}>{session[type].name}</Link></Title>
                  {type == 'event' && <EventRegister data={session.event} />}
                  {type == 'formation' && <FormationRegister data={session.formation} />}
                    <Title level={2}>{session.number} - {session.name}</Title>
                  {(session.record_link || session.record_embed) &&
                   (session.record_link != "" || session.record_embed != "") ? (
                     <div className="session__record__video"
                          dangerouslySetInnerHTML={{
                            __html: session.record_embed,
                          }}
                     >
                     </div>
                   ) : null}
                    <Row gutter={20} justify="space-between" align="top">
                        <Col span={12} xs={{ span: 24 }} lg={{ span: 20 }}>
                            <div className="session__description" dangerouslySetInnerHTML={{
                                __html: session.description,
                            }}>
                            </div>
                            <p>
                                Esclareça suas dúvidas no <a href="https://forum.netuno.org" target="_blank">Fórum </a>
                                e no nosso servidor do <a href="https://discord.com/invite/4sfXG6YWFu" target="_blank">Discord.</a>
                            </p>
                            <p className="sessions-list__date">
                              {Core.toUserLocalDateTime(session.start_date_time)} até{" "}
                              {Core.toUserLocalTime(session.end_date_time)} &nbsp;
                              {session.scheduled && <CalendarLinks session={session} />}
                            </p>

                        </Col>
                        <Col>
                            <ParticipantsSessionList participants={session.participants} />
                        </Col>
                    </Row>
                  { session.has_material || session.has_exercise ?
                  <Tabs
                    className="session__tabs"
                    items={[
                      ...(session.has_material ? [{
                        key: "material",
                        label: <><AlignCenterOutlined className="anticon" />Material</>,
                        children:
                        <>
                          <Material uid={uid}/>
                        </>
                      }] : []),
                      ...(session.has_exercise ? [{
                        key: "exercise",
                        label: <><EditOutlined className="anticon" />Exercício</>,
                        children:
                        <>
                          <Exercise uid={uid}/>
                        </>
                      }] : [])
                    ]}
                  /> : null}
                    <Button className="go-back-btn" type="link" onClick={() => navigate(-1)}><ArrowLeftOutlined /> Voltar</Button>
                </div>
            }
        </>
    );
}

export default Session;
