import React, { useEffect, useState } from "react";

import { Spin, Typography, Tag, Col, Row, notification } from "antd";

import {
  SoundOutlined,
  ClockCircleOutlined,
  GithubOutlined,
} from "@ant-design/icons";

import _service from "@netuno/service-client";

import { useParams } from "react-router-dom";

import Back from "../../components/Back";
import Loading from "../../components/Loading";
import EventRegister from "../../components/EventRegister";
import SessionsList from "../../components/SessionsList";
import ParticipantsList from "../../components/ParticipantsList";

import "./index.less";

const { Title } = Typography;

export default function Event() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const { uid } = useParams();

  useEffect(() => {
    _service({
      url: "/event",
      data: { uid },
      success: (response) => {
        if (response.json) {
          setData(response.json);
          setLoading(false);
        }
      },
      fail: (e) => {
        setLoading(false);
        console.error("Event Service Error", e);
        notification["error"]({
          message: "Detalhes do Evento",
          description:
            "Não foi possível carregar os detalhes do evento, tente mais tarde ou entre em contato com o suporte.",
        });
      },
    });
  }, []);
  if (loading) {
    return (
      <Loading page />
    );
  }
  const servicePrefix = _service.config().prefix;
  return (
    <div className="event">
      <Back to="/events" />
      <Row gutter={20} justify="space-around" align="middle">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <div
            className="event__cover"
            style={{
              backgroundImage: `url(/images/event/${data.imagem})`,
            }}
          ></div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Title className="event__title" level={3}>
            {data.name}
          </Title>
          <EventRegister data={data} />
          <p className="event__date">
            De {data.start_date} até {data.end_date}.
          </p>
          <p>
            <Tag className="tag__event">
              <SoundOutlined />
              Evento
            </Tag>
            {data.duration ? (
              <Tag className="tag__duration">
                <ClockCircleOutlined />
                {data.duration} horas
              </Tag>
            ) : (
              <Tag className="tag__duration">
                <ClockCircleOutlined />- horas
              </Tag>
            )}
          </p>
          {data?.participants?.length > 0 && (
            <ParticipantsList participants={data.participants} />
          )}
        </Col>
      </Row>
      <Row gutter={20} justify="center" align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <p
            className="formation__description"
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <p className="event__description">
            Esclareça suas dúvidas no{" "}
            <a href="https://forum.netuno.org" target="_blank">
              Fórum{" "}
            </a>
            e no nosso servidor do{" "}
            <a href="https://discord.com/invite/4sfXG6YWFu" target="_blank">
              Discord.
            </a>
          </p>
          {data.git && data.git != "" && (
            <p className="event__git">
              <a
                className="event__git--repository"
                href={data.git}
                target="_blank"
              >
                <GithubOutlined />
                Acesse o repositório
              </a>
            </p>
          )}
        </Col>
      </Row>
      <SessionsList type="event" data={data} />
      <Back to="/events" />
    </div>
  );
}
