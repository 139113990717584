// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/antd/dist/reset.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile-menu .user-avatar {\n  margin-bottom: 4px;\n}\n.incomplete-profile-warning > .ant-modal-content > .ant-modal-body > .ant-row {\n  margin-bottom: 12px;\n}\n.incomplete-profile-warning__icon {\n  font-size: 1.5rem;\n  color: #5b5ce1;\n}\n.incomplete-profile-warning__message {\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/HeaderUserInfo/index.less"],"names":[],"mappings":"AAEA;EAEI,kBAAA;AADJ;AAKA;EAIQ,mBAAA;AANR;AAUE;EACE,iBAAA;EACA,cAAA;AARJ;AAWE;EACE,kBAAA;AATJ","sourcesContent":["@import \"../../styles/variables.less\";\n\n.profile-menu {\n  .user-avatar {\n    margin-bottom: 4px;\n  }\n}\n\n.incomplete-profile-warning {\n  >.ant-modal-content {\n    >.ant-modal-body {\n      >.ant-row {\n        margin-bottom: 12px;\n      }\n    }\n  }\n  &__icon {\n    font-size: 1.5rem;\n    color: @primary;\n  }\n\n  &__message {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
