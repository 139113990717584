import React, { useEffect, useState } from 'react';

import { Navigate, useLocation } from "react-router-dom";

import _auth from '@netuno/auth-client';

function Auth({ children }) {
  const location = useLocation();

  if (!_auth.isLogged()) {
    return <Navigate to="/login" replace />;
  }
  
  return children;
}

export default Auth;
