import React, { useState, useEffect } from "react";
import _auth from "@netuno/auth-client";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./index.less";

export default function Techs(props) {
  const [currentTech, setCurrentTech] = useState(0);
  const [techAnimationTime, setTechAnimationTime] = useState(0);
  const techs = [
    {
      name: "Netuno",
      link: "https://www.netuno.org/",
    },
    {
      name: "ReactJS",
      link: "https://reactjs.org/",
    },
    {
      name: "Ant.Design",
      link: "https://ant.design/",
    },
    {
      name: "Linux",
      link: "https://www.linux.org/",
    },
    {
      name: "GIT",
      link: "https://git-scm.com/",
    },
    {
      name: "PostgreSQL",
      link: "https://www.postgresql.org/",
    },
    {
      name: "MariaDB",
      link: "https://mariadb.org/",
    },
    {
      name: "OpenAPI",
      link: "https://swagger.io/specification/",
    },
  ];

  useEffect(() => {
    const time = Math.random() * 4;
    document.body.style.setProperty("--tech-animation-time", time + "s");
    setTechAnimationTime(time * 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (currentTech === techs.length - 1) {
        setCurrentTech(0);
      } else {
        setCurrentTech(currentTech + 1);
      }
    }, 3000);
  }, [currentTech]);

  const TechsAnimation = techs.map((tech, i) => {
    if (i === currentTech) {
      return (
        <CSSTransition
          classNames="techs-animation"
          timeout={{
            exit: techAnimationTime,
            enter: techAnimationTime,
          }}
          key={i}
          in={true}
        >
          <a className="tech-item" href={tech.link} target="_blank">
            <span>{tech.name}</span>
          </a>
        </CSSTransition>
      );
    }
  });

  return <TransitionGroup>{TechsAnimation}</TransitionGroup>;
}
