import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { Avatar, Button, Col, Dropdown, Modal, notification, Row, Spin, Typography } from "antd";
import { DownOutlined, LogoutOutlined, EditOutlined, WarningOutlined } from "@ant-design/icons";

import _service from '@netuno/service-client';
import _auth from '@netuno/auth-client';
import Core from '../../Core'

import { loggedUserInfoAction } from "../../redux/actions";

import "./index.less";

function HeaderUserInfo({
  loggedUserInfo,
  loggedUserInfoReload,
  loggedUserInfoAction,
  onLogout
}) {
  const [loadingInfo, setLoadingInfo] = useState(false && Core.user == null);
  const [avatarImageURL, setAvatarImageURL] = useState('/images/profile-default.png');
  const [showAlertModal, setShowAlertModal] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const menuIsLogged = [
    {
      key: 1,
      label: (
        <Link to="/profile">
          <EditOutlined /> Editar Perfil
        </Link>
      )
    },
    {
      key: 2,
      label: (
        <Link to="/login" onClick={onLogout} className="profile-menu__logout">
          <LogoutOutlined /> Terminar Sessão
        </Link>
      )
    }
  ];

  const onAcceptCompleteProfile = () => {
    setShowAlertModal(false);
    navigate("/profile")
  };

  useEffect(() => {
    setLoadingInfo(true);
    _service({
      method: "GET",
      url: "people/info",
      success: (response) => {
        if (response.json) {

          loggedUserInfoAction(response.json);
          Core.user = response.json;

        }
        setLoadingInfo(false);
      },
      fail: (e) => {
        setLoadingInfo(false);
        notification["error"]({
          message: "Informações de Login",
          description:
            "Ocorreu um erro a carregar os dados do usuário, por favor tente novamente mais tarde ou entre em contato com o suporte.",
        });
      },
    });
  }, [loggedUserInfoReload]);

  useEffect(() => {
    if (loggedUserInfo && loggedUserInfo.avatar) {
      setAvatarImageURL(null);
      setTimeout(() => setAvatarImageURL(`${_service.config().prefix}/people/avatar?uid=${loggedUserInfo.uid}&${new Date().getTime()}`), 250);
    }
  }, [loggedUserInfo]);

  useEffect(() => {
    if (loggedUserInfo) {
      const {
        social_networks: socialNetworks,
        phone
      } = loggedUserInfo;
      const perfilIsComplete =
        socialNetworks.find(({ code }) => code === "linkedin")
        && phone;
      const shouldShowAlertModal = [
        !perfilIsComplete,
        location.pathname !== "/profile",
        !showAlertModal
      ].every((condition) => condition)

      if (shouldShowAlertModal) {
        setShowAlertModal(true);
      }
    }
  }, [loggedUserInfo]);

  if (loadingInfo) {
    return <Spin />;
  }

  return (
    <>
      <Dropdown
        key={"profile"}
        className="profile-menu"
        menu={{ items: menuIsLogged }}
      >
        <button
          className="ant-dropdown-link"
          onClick={(e) => e.preventDefault()}
        >
          <Avatar className="user-avatar" size={30} src={avatarImageURL} /> <span>{loggedUserInfo?.username} {" "}</span>
          <DownOutlined style={{ fontSize: 12 }} />
        </button>
      </Dropdown>
      <Modal
        centered
        className="incomplete-profile-warning"
        open={showAlertModal}
        footer={false}
        maskClosable={false}
        onCancel={onAcceptCompleteProfile}
      >
        <Row
          align="middle"
          justify="center"
        >
          <Col>
            <span
              className="incomplete-profile-warning__icon"
            >
              <WarningOutlined />
            </span>
          </Col>
        </Row>
        <Row
          align="middle"
          justify="center"
        >
          <Col>
            <Typography.Paragraph
              className="incomplete-profile-warning__message"
            >
              Seus dados estão incompletos. Para prosseguir na plataforma complete o seu cadastro com o número de WhatsApp e o seu LinkedIn.
            </Typography.Paragraph>
          </Col>
        </Row>
        <Row
          align="middle"
          justify="center"
        >
          <Col>
            <Button
              className="incomplet-profile-warning__button"
              type="primary"
              onClickCapture={onAcceptCompleteProfile}
            >
              Editar Pefil
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

const mapStateToProps = store => {
  const { loggedUserInfo, loggedUserInfoReload } = store.loggedUserInfoState;
  return {
    loggedUserInfo,
    loggedUserInfoReload
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  loggedUserInfoAction
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserInfo);
