import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { Spin, Alert, Button } from 'antd';
import { connect } from 'react-redux';
import Loading from '../../components/Loading';
import Play from '../../components/Play';
import Schedules from '../../components/Schedules';
import './index.less';

function Main({loggedUserInfo}) {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (loggedUserInfo) {
      setLoading(false);
    }
  }, [loggedUserInfo]);
  if (loading) {
    return <Loading page />;
  }
  return (
    <div className="dashboard-layout-content">
      {!loggedUserInfo.localization.timezone && (
        <div className="update-timezone-alert">
          <Alert
            message="Complete os seus Dados"
            description={
              <span>
                Por favor defina um país e um fuso horário para garantir
                que visualize todos os horários corretamente, e aproveite
                para completar o preenchimento de todas as informações
                adicionais do seu perfil.
              </span>
            }
            type="info"
            showIcon
            action={
              <Button
                size="large"
                type="primary"
                onClick={() => navigate("/profile")}
              >
                Editar Perfil
              </Button>
            }
          />
        </div>
      )}
      <Play/>
      <Schedules/>
    </div>
  );
}

const mapStateToProps = store => {
    const { loggedUserInfo } = store.loggedUserInfoState;
    return {
        loggedUserInfo
    };
};

export default connect(mapStateToProps, {})(Main);
