import React, { useEffect, useState } from 'react';

import { Row, Col, Typography, Button, Tabs, Divider, Space } from 'antd';

import PracticePassword from './PracticePassword';

const {Title, Text} = Typography;

function PracticeAccess({data}) {
  if (!data.practice) {
    return null;
  }
  return (
    <>
      <Row>
        <Col span={24}><Title level={3}>Acesso à Prática</Title></Col>
        <Col span={24}>
          <Space direction="vertical" size="middle">
            { data.practice.web.port > 0 &&
              <>
                <Space size="middle">
                  <Button type="primary" onClick={ () => window.open(`http://${data.practice.username}.${data.practice.ssh.host}:${data.practice.web.port}`)}>
                    Web Site
                  </Button>
                  { data.practice.netuno.port > 0 &&
                    <Button type="primary" onClick={ () => window.open(`http://${data.practice.username}.${data.practice.ssh.host}:${data.practice.netuno.port}`)}>
                      Netuno
                    </Button>
                  }
                </Space>
                <div>
                  <Title level={4}>API REST</Title>
                  <p>Prefixo da URL dos serviços do Netuno disponíveis em HTTPS:</p>
                  <Text code>https://{data.practice.username}.bootcamp.dev.netuno.org:20193/services/</Text>
                </div>
              </>
            }
            <Tabs
              items={[
                {
                  label: 'Acesso SSH',
                  key: '1',
                  children:
                  <div>
                    <Title level={5}>Acesso SSH:</Title>
                    <Tabs
                      items={[
                        {
                          label: 'Web Browser',
                          key: '1',
                          children:
                          <>
                            <p>
                              <Button type="primary" onClick={ () => window.open(`http://${data.practice.username}:${data.practice.password}@${data.practice.ssh.host}${data.practice.ssh.link}`) }>
                                Abrir Terminal
                              </Button>
                            </p>
                            <p>Utilize o acesso gerado:</p>
                            <p>
                              User: <Text code>{data.practice.username}</Text><br/>
                              Pass: <PracticePassword data={data}/>
                            </p>
                          </>
                        },
                        {
                          label: 'Linux & Mac',
                          key: '2',
                          children:
                          <>
                            <p>Abra o terminal e execute o comando:</p>
                            <p>
                              <Text code>ssh {data.practice.username}@{data.practice.ssh.host} -p {data.practice.ssh.port}</Text>
                            </p>
                            <p>
                              Utilize a password gerada: <PracticePassword data={data}/>
                            </p>
                          </>
                        },
                        {
                          label: 'Windows',
                          key: '3',
                          children:
                          <>
                            <p>Sugerimos a utilização de um deste programas para acessar o terminal SSH através do Windows:</p>
                            <Tabs
                              items={[
                                {
                                  label: 'Putty',
                                  key: '1',
                                  children:
                                  <>
                                    <p>O <a href="https://www.chiark.greenend.org.uk/~sgtatham/putty/latest.html" target="_blank">Putty</a> é um programa bem antigo, muito leve e muito fácil de utilizar.</p>
                                    <p>Instale o <a href="https://www.chiark.greenend.org.uk/~sgtatham/putty/latest.html" target="_blank">Putty</a>.</p>
                                    <p>Depois que instalar execute a aplicação <Text code>Putty</Text>.</p>
                                    <p>Preencha os campos:</p>
                                    <ul>
                                      <li>Host Name: <Text code>{data.practice.username}@{data.practice.ssh.host}</Text></li>
                                      <li>Port: <Text code>{data.practice.ssh.port}</Text></li>
                                    </ul>
                                    <p>Clique no botão <Text code>Open</Text></p>
                                    <p>
                                      Utilize a password gerada: <PracticePassword data={data}/>
                                    </p>
                                  </>
                                },
                                {
                                  label: 'SmarTTY',
                                  key: '2',
                                  children:
                                  <>
                                    <p>O <a href="https://sysprogs.com/SmarTTY/" target="_blank">SmarTTY</a> é um programa com funcionalidades adicionais, permite por exemplo a navegação e a transferência de arquivos integrada, sugestões de comandos, e muito mais.</p>
                                    <p>Apesar dos recursos avançados pode ser mais complicado para utilizar.</p>
                                    <p>Instale o <a href="https://sysprogs.com/SmarTTY/" target="_blank">SmarTTY</a>.</p>
                                    <p>Depois que instalar execute a aplicação <Text code>SmarTTY</Text>.</p>
                                    <p>Clique no botão <Text code>New SSH connection...</Text></p>
                                    <p>Preencha os campos:</p>
                                    <ul>
                                      <li>Hostname: <Text code>{data.practice.ssh.host}:{data.practice.ssh.port}</Text></li>
                                      <li>Username: <Text code>{data.practice.username}</Text></li>
                                    </ul>
                                    <p>Clique no botão <Text code>Connect</Text></p>
                                    <p>
                                      Utilize a password gerada: <PracticePassword data={data}/>
                                    </p>
                                  </>
                                },
                                {
                                  label: 'Termius',
                                  key: '3',
                                  children:
                                  <>
                                    <p>O <a href="https://termius.com/free-ssh-client-for-windows" target="_blank">Termius</a> é um programa moderno, intuitivo, mais pesado e muito fácil de utilizar.</p>
                                    <p>Não é indicado para computadores antigos ou lentos.</p>
                                    <p>Instale o <a href="https://termius.com/free-ssh-client-for-windows" target="_blank">Termius</a>.</p>
                                    <p>Depois que instalar execute a aplicação Termius.</p>
                                    <p>Clique no menu a esquerda em <Text code>Hosts</Text>.</p>
                                    <p>Clique no botão em cima <Text code>New Host</Text>.</p>
                                    <p>Na aba a direita que apareceu preencha os campos:</p>
                                    <ul>
                                      <li>Address: <Text code>{data.practice.ssh.host}</Text></li>
                                      <li>Ative o <Text code>SSH</Text></li>
                                      <li>Port: <Text code>{data.practice.ssh.port}</Text></li>
                                      <li>Username: <Text code>{data.practice.username}</Text></li>
                                      <li>Password: <PracticePassword data={data}/></li>
                                    </ul>
                                    <p>Duplo click no botão desta conexão que aparece de imadiato do lado esquerdo da aba.</p>
                                  </>
                                }
                              ]}/>
                          </>
                        }]}/>
                  </div>
                },
                {
                  label: 'Acesso Base de Dados',
                  key: '2',
                  children:
                  <div>
                    <Title level={5}>Acesso Base de Dados:</Title>
                    <p>No seu terminal SSH e execute o comando:</p>
                    <p>
                      <Text code>psql -U {data.practice.username} -W -h localhost {data.practice.username}_db</Text>
                    </p>
                    <p>
                      Utilize a sua password gerada: <PracticePassword data={data}/>
                    </p>
                  </div>
                },
                {
                  label: 'Acesso Netuno',
                  key: '3',
                  children:
                  <div>
                    <Title level={5}>Acesso ao backoffice Netuno:</Title>
                    <p>
                      <a href={`http://${data.practice.username}.${data.practice.ssh.host}:${data.practice.netuno.port}`} target="_blank">
                        Abrir o Netuno
                      </a>
                    </p>
                    <p>
                      User: <Text code>dev</Text><br/>
                      Pass: <PracticePassword data={data}/>
                    </p>
                    <p>
                      User: <Text code>admin</Text><br/>
                      Pass: <PracticePassword data={data}/>
                    </p>
                    <p>
                      O código da sua aplicação Netuno fica dentro da pasta: <Text code>~/netuno-app</Text>
                    </p>
                    <p>
                      Para visualizar os logs da sua aplicação Netuno execute um destes comandos no terminal:
                    </p>
                    <ul>
                      <li><Text code>~/netuno-app/log.sh</Text></li>
                      <li><Text code>tail -f /srv/netuno/server.out</Text></li>
                    </ul>
                  </div>
                }]}/>
          </Space>
        </Col>
      </Row>
      <Divider />
    </>
  );
}

export default PracticeAccess;
