import React, { useState, useEffect, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Layout, Typography, Form, Input, Button, notification } from "antd";
import { PasswordInput } from "antd-password-input-strength";
import _service from "@netuno/service-client";
import SiderHome from "../../components/SiderHome";

import "./index.less";

const { Title } = Typography;
const { Content, Sider } = Layout;

export default function Recovery(props) {
  const [ready, setReady] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [hash, setHash] = useState(false);
  const recoveryForm = useRef(null);

  const location = useLocation();

  useEffect(() => {
    setHash(location.hash.substring(1));
  }, [location]);

  function onFinish(values) {
    setSubmitting(true);
    const { password } = values;
    _service({
      method: "POST",
      url: "recovery",
      data: {
        password,
        key: hash,
      },
      success: (response) => {
        if (response.json.result) {
          notification["success"]({
            message: "Alteração de Senha",
            description: "A sua senha foi alterada com sucesso.",
          });
          setSubmitting(false);
          setReady(true);
        }
      },
      fail: (e) => {
        setSubmitting(false);
        if (e.response && e.response.status == 404) {
          notification["error"]({
            message: "Link Inválido",
            description:
              "O link é inválido e não foi possível alterar a sua senha, por favor reinicie o processo de recuperação do acesso.",
          });
        } else {
          notification["error"]({
            message: "Erro na alteração de senha",
            description:
              "Não foi possível alterar a sua senha, por favor contacte-nos através do chat de suporte.",
          });
        }
      },
    });
  }

  function onFinishFailed(errorInfo) {
    console.log("Failed:", errorInfo);
  }

  if (ready) {
    return <Navigate to="/login" replace />;
  } else if (window.location.hash && window.location.hash !== "") {
    return (
      <Layout>
        <Content className="recovery-container">
          <div className="content-body">
            <div className="content-title">
              <Title>Recuperar Acesso</Title>
            </div>
            <p>Alteração da senha da sua conta.</p>
            <Form
              ref={recoveryForm}
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Senha"
                name="password"
                rules={[
                  { required: true, message: "Insira a senha." },
                  {
                    type: "string",
                    message:
                      "Senha deverá ter entre 8 a 25 caracteres.",
                    min: 8,
                    max: 25,
                  },
                ]}
              >
                <PasswordInput disabled={submitting} maxLength={25} />
              </Form.Item>
              <Form.Item
                label="Confirmar a Senha"
                name="password_confirm"
                rules={[
                  {
                    required: true,
                    message: "Insira a confirmação da senha.",
                  },
                  {
                    type: "string",
                    message:
                      "Senha deverá ter entre 8 a 25 caracteres.",
                    min: 8,
                    max: 25,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "As palavras-passes não são iguais."
                      );
                    },
                  }),
                ]}
              >
                <Input.Password disabled={submitting} maxLength={25} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={submitting}>
                  Redefinir Senha
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Content>
        <SiderHome />
        {/* <Sider width={'50%'} style={{
                    backgroundImage: 'url(/images/sider-recovery.jpg)',
                    backgroundSize: 'cover',
                    backgroundPosition: '50% 50%'
                }}>
                    <span class="helper" />
                </Sider> */}
      </Layout>
    );
  }
  /* else {
        return <NotFoundPage />;
    } */
}
