import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Navigate, Link } from "react-router-dom";
import {
  Layout,
  Typography,
  Form,
  Input,
  Button,
  notification,
  Select,
  Carousel,
} from "antd";
import SiderHome from "../../components/SiderHome";
import { PasswordInput } from "antd-password-input-strength";
import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import _auth from "@netuno/auth-client";
import Typed from "react-typed";
import _service from "@netuno/service-client";

import "./index.less";

const { Title } = Typography;
const { Content, Sider } = Layout;

export default function Register(props) {

  const [ready, setReady] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [loadingTimeZones, setLoadingTimeZones] = useState(false);
  const [countries, setCountries] = useState([]);
  const [timeZones, setTimeZones] = useState([]);
  const [timeZonesActive, setTimeZonesActive] = useState([]);
  const [timeZoneRequired, setTimeZoneRequired] = useState(false);
  const [data, setData] = useState({localization: {}});
  
  const [registerForm] = Form.useForm();

  const contentStyle = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  useEffect(() => {
    if (_auth.isLogged()) {
      window.scrollTo(0, 0);
    }
    window.scrollTo(0, 0);
    onFetchCountries();
  }, []);

  function onFinish(values) {
    setSubmitting(true);
    const { username, password, mail, name, localization } = values;
    _service({
      method: "PUT",
      url: "people",
      data: {
        name,
        username,
        password,
        mail,
        localization
      },
      success: (response) => {
        if (response.json.result) {
          notification["success"]({
            message: "Conta Criada",
            description: "A conta foi criada com sucesso, pode iniciar sessão.",
          });
          setSubmitting(false);
          setReady(true);
        } else {
          notification["warning"]({
            message: "Usuário existente",
            description: response.json.error,
          });
          setSubmitting(false);
        }
      },
      fail: (e) => {
        setSubmitting(false);
        console.error('Register Failed', e);
        if (e.response) {
          e.response.json().then((data) => {
            if (data.error == 'already-exists') {
              notification["warning"]({
                message: "Conta Existe",
                description:
                "Não foi possível criar a conta, por que o usuário já existe.",
              });
            } else {
              notification["error"]({
                message: "Erro na Criação de Conta",
                description:
                "Não foi possível criar a conta, contacte-nos através dos meios disponíveis no rodapé.",
              });
            }
          });
        } else {
          notification["error"]({
            message: "Erro na Criação de Conta",
            description:
            "Não foi possível criar a conta, contacte-nos através dos meios disponíveis no rodapé.",
          });
        }
      },
    });
  }

  function onFetchCountries() {
    setLoadingCountries(true);
    _service({
      method: 'GET',
      url: 'country/list',
      success: (response) => {
        setLoadingCountries(false);
        if (response.json) {
          const list = response.json;
          setCountries(list);
          onFetchTimeZones();
        }
      },
      fail: (e) => {
        setLoadingCountries(false);
        setLoading(false);
        console.error(`Countries`, e);
        notification["error"]({
          message: 'Falha nos Países',
          description: 'Ocorreu um erro a carregar os países, por favor tente novamente mais tarde ou entre em contato com o suporte.',
        });
      }
    });
  }

  function onFinishFailed(errorInfo) {
    console.log("Failed:", errorInfo);
  }
  
  function onFetchTimeZones() {
    setLoading(true);
    _service({
        method: 'GET',
        url: 'timezone/list',
        success: (response) => {
            setLoadingTimeZones(false);
            setLoading(false);
            if (response.json) {
                const list = response.json;
                setTimeZones(list);
            }
        },
        fail: (e) => {
            setLoadingTimeZones(false);
            setLoading(false);
            console.error(`TimeZones`, e);
            notification["error"]({
                message: 'Falha nos Fusos Horários',
                description: 'Ocorreu um erro a carregar os fusos horários, por favor tente novamente mais tarde ou entre em contato com o suporte.',
            });
        }
    });
  }
  function onFetchTimeZones() {
    setLoading(true);
    _service({
      method: 'GET',
      url: 'timezone/list',
      success: (response) => {
        setLoadingTimeZones(false);
        setLoading(false);
        if (response.json) {
          const list = response.json;
          setTimeZones(list);
        }
      },
      fail: (e) => {
        setLoadingTimeZones(false);
        setLoading(false);
        console.error(`TimeZones`, e);
        notification["error"]({
          message: 'Falha nos Fusos Horários',
          description: 'Ocorreu um erro a carregar os fusos horários, por favor tente novamente mais tarde ou entre em contato com o suporte.',
        });
      }
    });
  }
  
  function onCountryChange(value) {
    const newData = {...data};
    newData.localization.timezone = "";
    newData.localization.country = value;
    registerForm.setFieldsValue(newData);
    setData(newData);
    setTimeZonesActive(
      timeZones.filter((timeZone) => timeZone.country_code == value)
    );
    if (value) {
      setTimeZoneRequired(true);
    } else {
      setTimeZoneRequired(false);
    }
  }


  if (_auth.isLogged()) {
    return <Navigate to="/reserved-area" replace />;
  } else if (ready) {
    return <Navigate to="/login" replace />;
  } else {
    return (
      <Layout>
        <Content className="register-container">
          <div className="content-body">
            <div className="content-title">
              <Title>Criar conta</Title>
            </div>
            <p>
              Crie uma conta para poder aceder à sua área reservada da academia.
            </p>
            <Form
              form={registerForm}
              layout="vertical"
              name="basic"
              initialValues={data}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autocomplete="false"
            >
              
              <Form.Item
                // label="Nome"
                name="name"
                rules={[
                  { required: true, message: "Insira o seu nome." },
                  {
                    type: "string",
                    message:
                      "Nome inválido, apenas letras minúsculas e maiúsculas.",
                    pattern:
                      "^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined />}
                  placeholder="Nome"
                  disabled={submitting}
                  maxLength={25}
                />
              </Form.Item>
              <Form.Item
                // label="Usuário"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Insira o seu nome de usuário.",
                  },
                  {
                    type: "string",
                    message:
                      "Usuário inválido, apenas letras minúsculas e números, deve iniciar com uma letra.",
                    pattern: "^[a-z]+[a-z0-9]{1,24}$",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined/>}
                  placeholder="Usuário"
                  disabled={submitting}
                  maxLength={25}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                // label="E-mail"
                name="mail"
                rules={[
                  { type: "email", message: "O e-mail inserido não é válido." },
                  { required: true, message: "Insira o e-mail." },
                ]}
              >
                <Input
                  prefix={<MailOutlined />}
                  placeholder="E-mail"
                  disabled={submitting}
                  maxLength={250}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item name={['localization', 'country']} label="País" placeholder="País" rules={[{ required: false }]}>
                <Select onChange={onCountryChange} loading={loadingCountries} allowClear>
                  { countries.map((country) => <Select.Option value={country.code}>{country.name}</Select.Option>)}
                </Select>
              </Form.Item>
              <Form.Item name={['localization', 'timezone']} label="Fuso Horário" rules={[{ required: timeZoneRequired }]}>
                <Select disabled={ timeZonesActive.length == 0 } loading={loadingTimeZones}>
                  { timeZonesActive.map((timeZone) => <Select.Option value={timeZone.code}>{timeZone.name}</Select.Option>)}
                </Select>
              </Form.Item>
              <Form.Item
                // label="Senha"
                name="password"
                rules={[
                  { required: true, message: "Insira a senha." },
                  {
                    type: "string",
                    message:
                      "Senha deverá ter entre 8 a 25 caracteres.",
                    min: 8,
                    max: 25,
                  },
                ]}
              >
                <PasswordInput
                  prefix={<LockOutlined />}
                  placeholder="Senha"
                  disabled={submitting}
                  maxLength={25}
                />
              </Form.Item>
              <Form.Item
                // label="Confirmar a senha"
                name="password_confirm"
                rules={[
                  {
                    required: true,
                    message: "Insira a confirmação da senha.",
                  },
                  {
                    type: "string",
                    message:
                      "Senha deverá ter entre 8 a 25 caracteres.",
                    min: 8,
                    max: 25,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "As senhas não são iguais."
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Confirmar a Senha"
                  disabled={submitting}
                  maxLength={25}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={submitting}>
                  Criar Conta
                </Button>
              </Form.Item>
              <hr />
              <span>
                <p>ou</p>
              </span>
              <Link to="/login">
                <Button type="default" className={"register-btn"}>
                  Iniciar sessão
                </Button>
              </Link>
            </Form>
          </div>
        </Content>
        <SiderHome />
      </Layout>
    );
  }
}
