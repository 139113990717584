// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/antd/dist/reset.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".certificate-container {\n  padding: 60px 40px !important;\n}\n@media screen and (max-width: 768px) {\n  .certificate-container {\n    width: 100% !important;\n    padding: 0 !important;\n  }\n}\n.certificate-container .content-body {\n  background-color: #161616;\n  width: 100%;\n  border-radius: 0.5rem;\n  padding: 1.5rem !important;\n  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.3);\n  text-align: center;\n}\n@media screen and (max-width: 768px) {\n  .certificate-container .content-body {\n    margin-bottom: 0;\n    margin-top: 0;\n  }\n}\n.certificate-container .content-body img {\n  width: 100%;\n  max-width: 1200px;\n}\n.certificate-container .content-body a {\n  display: block;\n  margin-top: 1.5rem;\n  font-size: 1.5em;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Certificate/index.less"],"names":[],"mappings":"AAEA;EAEE,6BAAA;AADF;AAGE;EAAA;IACE,sBAAA;IACA,qBAAA;EAAF;AACF;AAPA;EASI,yBAAA;EACA,WAAA;EACA,qBAAA;EACA,0BAAA;EACA,2CAAA;EAMA,kBAAA;AAJJ;AAAI;EAAA;IACE,gBAAA;IACA,aAAA;EAGJ;AACF;AArBA;EAqBM,WAAA;EACA,iBAAA;AAGN;AAzBA;EAyBM,cAAA;EACA,kBAAA;EACA,gBAAA;AAGN","sourcesContent":["@import \"../../styles/variables.less\";\n\n.certificate-container {\n\n  padding: 60px 40px !important;\n\n  @media screen and (max-width: @mobile-width) {\n    width: 100% !important;\n    padding: 0 !important;\n  }\n  .content-body {\n    background-color: rgb(22, 22, 22);\n    width: 100%;\n    border-radius: 0.5rem;\n    padding: 1.5rem !important;\n    box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.3);\n\n    @media screen and (max-width: @mobile-width) {\n      margin-bottom: 0;\n      margin-top: 0;\n    }\n    text-align: center;\n    img {\n      width: 100%;\n      max-width: 1200px;\n    }\n    a {\n      display: block;\n      margin-top: 1.5rem;\n      font-size: 1.5em;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
