import React, {useEffect, useState} from 'react';

import _auth from '@netuno/auth-client';
import _service from '@netuno/service-client';

import { Navigate, useParams, useNavigate } from 'react-router-dom';

import {Button, Spin, Typography, notification} from 'antd';

import { ArrowLeftOutlined } from '@ant-design/icons';

import MDEditor from '@uiw/react-md-editor';

import './index.less';

const { Title, Text } = Typography;

function FormationMaterial({uid}) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  
  const navigate = useNavigate();

  useEffect(() => {
    _service({
      url: "/formation/material",
      data: { uid },
      success: (response) => {
        if (response.json) {
          setData(response.json);
          setLoading(false);
        }
      },
      fail: (e) => {
        console.error("Formation Service Error", e);
        notification["error"]({
          message: 'Material da Formação',
          description: 'Não foi possível carregar o material da formação, tente mais tarde ou entre em contato com o suporte.',
        });
      }
    });
  }, []);
  if (!_auth.isLogged()) {
    return <Navigate to="/login" replace />;
  }
  if (loading) {
    return (
      <div className="formation-material__loading"><Spin /></div>
    );
  }
  return (
    <div className="formation-material">
      <div className="formation-material__content">
        <MDEditor.Markdown source={data.content} />
      </div>
    </div>
  );
}

export default FormationMaterial;
