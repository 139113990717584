import React, { useEffect, useState } from 'react';

import _service from "@netuno/service-client";

import { Typography, notification, Table, Tag, Input, Row } from 'antd';

import { Link } from "react-router-dom";

import './index.less';

const { Title } = Typography;

const pageSize = 20;
const timerSearchs = [];

export default () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [memberFilter, setMemberFilter] = useState("");
    const [order, setOrder] = useState({});
    const [pagination, setPagination] = useState({
      current: 1,
      pageSize,
      total: 0
    });

    const toolTips = {
      user: {
        ascend: "A-Z",
        descend: "Z-A",
      },
      sessions: {
        ascend: "Crescente",
        descend: "Decrescente",
      },
      formations: {
        ascend: "Crescente",
        descend: "Decrescente",
      },
      events: {
        ascend: "Crescente",
        descend: "Decrescente",
      },
      pontuation: {
        ascend: "Crescente",
        descend: "Decrescente",
      }
    };

    useEffect(() => {
      loadTable();
    }, []);

    useEffect(() => {
      for (let i = timerSearchs.length; i > 0; i--) {
        clearTimeout(timerSearchs.shift());
      }

      timerSearchs.push(setTimeout(() => {
        loadTable({
          ...order,
          pagination: {
            ...pagination,
            current: 1
          }
        });
      }, 1000));
    }, [memberFilter]);

    const getSorterTooltip = (orders, currentOrder) => {
      const toolTip = orders[orders.indexOf(currentOrder)];
      return {
        title: toolTips[order.order_by][toolTip]
      };
    };

    const columns = [
        {
            key: 'user',
            width: "20%",
            title: "Usuário",
            dataIndex: 'user',
            render: (user) => <Link to={`/profile/${user}`}>{user}</Link>,
            sorter: true,
            sortDirections: ["ascend", "descend"],
            showSorterTooltip: order.order_by === "user" ?
              getSorterTooltip(["ascend", "descend"], order.order_direction || "ascend") :
              null,
            sortOrder: order.order_by === "user" ?
              order.order_direction :
              null
        },
        {
            key: 'sessions',
            width: "20%",
            title: "Sessões",
            dataIndex: 'sessions',
            render: (sessions) => sessions,
            sorter: true,
            sortDirections: ["ascend", "descend"],
            sortOrder: order.order_by === "sessions" ?
              order.order_direction :
              null,
            showSorterTooltip: order.order_by === "sessions" ?
              getSorterTooltip(["ascend", "descend"], order.order_direction || "ascend") :
              null
        },
        {
            key: 'events',
            width: "20%",
            title: "Eventos",
            dataIndex: 'events',
            render: (events) => events,
            sorter: true,
            sortDirections: ["ascend", "descend"],
            sortOrder: order.order_by === "events" ?
              order.order_direction :
              null,
            showSorterTooltip: order.order_by === "events" ?
              getSorterTooltip(["ascend", "descend"], order.order_direction || "ascend") :
              null
        },
        {
            key: 'formations',
            width: "20%",
            title: "Formações",
            dataIndex: 'formations',
            render: (formations) => formations,
            sorter: true,
            sortDirections: ["ascend", "descend"],
            sortOrder: order.order_by === "formations" ?
              order.order_direction :
              null,
            showSorterTooltip: order.order_by === "formations" ?
              getSorterTooltip(["ascend", "descend"], order.order_direction || "ascend") :
              null
        },
        {
            key:'pontuation',
            title: "Pontuação",
            width: "20%",
            dataIndex: 'pontuation',
            sorter: true,
            sortDirections: ["descend", "ascend"],
            sortOrder: order.order_by === "pontuation" ?
              order.order_direction :
              null,
            showSorterTooltip: order.order_by === "pontuation" ?
              getSorterTooltip(["descend", "ascend"], order.order_direction || "descend") :
              null,
        }
    ];

    const loadTable = (settings)=> {
      if (settings == null || typeof settings == "undefined") {
        settings = { };
      }

      if (typeof settings.pagination == 'undefined') {
        settings.pagination = pagination;

      }

      if (settings.order_by != order.order_by || settings.order_direction != order.order_direction) {
        settings.pagination.current = 1;
      }

      settings.filter = memberFilter;

      setLoading(true);
      setPagination(settings.pagination);
      _service({
        method: 'POST',
        url: 'member/list',
        data: settings,
        success: (response) => {
          setData(response.json.list);
          setLoading(false);
          setPagination({
            ...settings.pagination,
            total: response.json.total
          });
        },
        fail: (e) => {
          setLoading(false);
          notification['error']({
            message: 'Não foi possivel carregar os dados.',
            description: e.response.json.error,
          });
        }
      });
    };

    const onTableChange = (newPagination, filter, sorter) => {
      const newOrder = {
        order_by: sorter.order && sorter.field,
        order_direction: sorter.order
      };
      const settings = {
        pagination: newPagination,
        ...newOrder
      };

      loadTable(settings);

      setOrder(newOrder);
    };

    return (
        <div className="members">
            <Title level={3}>Membros</Title>
            <div className="members__content">
                <p>Lista de todas as pessoas inscritas na plataforma.</p>
                <Input.Search
                  placeholder="Filtrar membros"
                  value={memberFilter}
                  onChange={(event) => setMemberFilter(event.target.value)}
                  onSearch={() => loadTable({
                    ...order,
                    pagination: {
                      ...pagination,
                      current: 1
                    }
                  })}
                />
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={data.map((d) => ({ ...d, key: d.uid}) )}
                  pagination={pagination}
                  onChange={onTableChange}
                />
            </div>
        </div>
    );
}
