// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/antd/dist/reset.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".members__content > .ant-input-group-wrapper {\n  margin-bottom: 1em;\n}\n.members__content > .ant-input-group-wrapper > .ant-input-wrapper {\n  border: 1px solid #5051c2;\n  border-radius: 4px;\n}\n.members__content > .ant-input-group-wrapper > .ant-input-wrapper > input {\n  border: none;\n}\n.members__content > .ant-input-group-wrapper > .ant-input-wrapper > span {\n  border-left: 1px solid #424242 !important;\n  inset-inline-start: 0px !important;\n  background-color: #5051c2;\n}\n.members__content > .ant-input-group-wrapper > .ant-input-wrapper > span > .ant-btn {\n  border: none;\n  background-color: #5052c2;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Members/index.less"],"names":[],"mappings":"AAGE;EAGI,kBAAA;AAHN;AAAE;EAMM,yBAAA;EACA,kBAAA;AAHR;AAJE;EAUQ,YAAA;AAHV;AAPE;EAcQ,yCAAA;EACA,kCAAA;EACA,yBAAA;AAJV;AAZE;EAmBU,YAAA;EACA,yBAAA;AAJZ","sourcesContent":["@import '../../styles/variables.less';\n\n.members {\n  &__content {\n\n    > .ant-input-group-wrapper {\n      margin-bottom: 1em;\n\n      > .ant-input-wrapper {\n        border: 1px solid #5051c2;\n        border-radius: 4px;\n\n        > input {\n          border: none;\n        }\n\n        > span  {\n          border-left: 1px solid #424242 !important;\n          inset-inline-start: 0px !important;\n          background-color: #5051c2;\n\n          > .ant-btn {\n            border: none;\n            background-color: #5052c2;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
