import React from 'react';

import { Typography } from 'antd';

import _service from '@netuno/service-client';

import HistoryTabs from '../../components/HistoryTabs';

import './index.less';

const { Title } = Typography;

export default function History() {
    return (
        <div className="history">
          <Title level={3}>Histórico</Title>
          <div className="history__introduction">
            <p>Aqui tem o histórico das atividades que participou, aproveite para fazer a revisão dos temas.</p>
          </div>
          <HistoryTabs initialTab="events" />
        </div>
    );
}
