import moment from 'moment-timezone';

let _user = null;

export default class Core {
    static get user() {
        return _user;
    }

    static set user(user) {
        _user = user;
    }
    static toUserLocalMoment(baseDateTime) {
        const timeZone = Core.user.localization.timezone ? Core.user.localization.timezone : "Europe/Lisbon";
        const baseMomentTZ =  moment.tz(baseDateTime.replace(" ", "T").replace(".0", "Z"), "Europe/Lisbon");
        return baseMomentTZ.clone().tz(timeZone);
    }

    static toUserLocalDateTime(baseDateTime) {
        const timeZone = Core.user.localization.timezone ? Core.user.localization.timezone : "Europe/Lisbon";
        const baseMomentTZ =  moment.tz(baseDateTime.replace(" ", "T").replace(".0", "Z"), "Europe/Lisbon");
        const userTimeZone = baseMomentTZ.clone().tz(timeZone).format("Z");
        const userDateTime = baseMomentTZ.clone().tz(timeZone).format().replace("T", " ").replace(userTimeZone, "");
        const finalDateTime = userDateTime.substring(0, userDateTime.lastIndexOf(':'));
        return finalDateTime;
    }

    static toUserLocalDate(baseDateTime) {
        const userDateTime = Core.toUserLocalDateTime(baseDateTime);
        const finalDate = userDateTime.substring(0, userDateTime.indexOf(' '));
        return finalDate;
    }

    static toUserLocalTime(baseDateTime) {
        const userDateTime = Core.toUserLocalDateTime(baseDateTime);
        const finalDateTime = userDateTime.substring(userDateTime.indexOf(' ') + 1);
        return finalDateTime;
    }
}
