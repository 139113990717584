import React, { useEffect, useState } from "react";

import {
  Typography,
  Divider,
  Button,
  Spin,
  Alert,
  Row,
  Col,
  Tag,
  notification,
  Badge,
} from "antd";

import {
  SoundOutlined,
  TrophyOutlined,
  VideoCameraOutlined,
  ClockCircleOutlined,
  WechatOutlined,
} from "@ant-design/icons";

import _service from "@netuno/service-client";

import { Link } from "react-router-dom";

import Countdown from "react-countdown";

import CalendarLinks from "../CalendarLinks";

import Core from "../../Core";

import "./index.less";

const { Title } = Typography;

export default ({ }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [enterLoading, setEnterLoading] = useState(false);
  const [entered, setEntered] = useState(false);
  const servicePrefix = _service.config().prefix;

  const onLoad = () => {
    _service({
      method: "GET",
      url: "/play",
      success: (response) => {
        if (response.json) {
          if (response.json.result != false) {
            setData(response.json);
          }
          setLoading(false);
        }
      },
      fail: (e) => {
        setLoading(false);
        console.error("Play Service Error", e);
        notification["error"]({
          message: "Play",
          description:
            "Não foi possível carregar o player, tente mais tarde ou entre em contato com o suporte.",
        });
      },
    });
  };

  useEffect(() => {
    onLoad();
  }, []);

  const onEnter = () => {
    setEnterLoading(true);
    _service({
      method: "POST",
      url: "/play/enter",
      data: { type: data.type, session: { uid: data.session.uid } },
      success: (response) => {
        if (response.json) {
          if (response.json.result == true) {
            window.scrollTo(0, 0);
            setEntered(true);
          }
          setEnterLoading(false);
        }
      },
      fail: (e) => {
        setEnterLoading(false);
        console.error("Play Enter Service Error", e);
        notification["error"]({
          message: "Entrar",
          description:
            "Não foi possível entrar na atividade, tente mais tarde ou entre em contato com o suporte.",
        });
      },
    });
  };

  if (loading) {
    return <Spin />;
  }
  if (data == null) {
    return null;
  }
  if (true) {
    return (
      <div className="play">
        {data.live ? (
          <Title level={3}>Sessão a decorrer agora:</Title>
        ) : (
            <Title level={3}>A sua próxima sessão:</Title>
          )}
        <div className="play__activity">
          <div className="play__activity__item">
            <Row gutter={20} justify="space-around" align="middle">
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Badge.Ribbon
                  text={
                    data.live ? (
                      <span>
                        <VideoCameraOutlined /> Ao Vivo
                      </span>
                    ) : (
                        <span>
                          <ClockCircleOutlined />{" "}
                          <Countdown
                            date={Core.toUserLocalMoment(
                              data.session.start_date_time
                            ).toDate()}
                            onTick={(e) => {
                              if (e.minutes === 4 && e.seconds <= 59) onLoad();
                            }}
                          />
                        </span>
                      )
                  }
                  color="purple"
                >
                  
                      <div
                        className="play__activity__item__cover"
                        style={{
                          backgroundImage: `url(/images/${data.type}/${data.image})`,
                        }}
                      >
                        {data.live ? (
                          <div className="play__activity__item__cover__shadow">
                            <div className="play__activity__item__number">
                              {data.session.number}
                            </div>
                            <div className="play__activity__item__cover__enter">
                              <p>
                                {entered == false && (
                                  <>
                                    <Title level={2}>Já começou!</Title>
                                    <p>Clique no botão abaixo para participar:</p>
                                    <p>
                                      <Button
                                        type="primary"
                                        size="large"
                                        onClick={onEnter}
                                        loading={enterLoading}
                                      >
                                        Entrar
                                      </Button>
                                    </p>
                                  </>
                                )}
                                { data.live && entered ? (
                                  <div className="play__activity__item__live">
                                    {data.session.video_embed != "" && (
                                      <div
                                        className="play__activity__item__live__video"
                                        dangerouslySetInnerHTML={{
                                          __html: data.session.video_embed,
                                        }}
                                      ></div>
                                    )}
                                    {data.session.chat_embed != "" && (
                                      <div
                                        className="play__activity__item__live__chat"
                                        dangerouslySetInnerHTML={{
                                          __html: data.session.chat_embed,
                                        }}
                                      ></div>
                                    )}
                                    {(data.session.video_link != "" || data.session.chat_link != "") &&
                                     <>
                                       <Title level={4}>Participe agora:</Title>
                                       <p>
                                         {data.session.video_link != "" && (
                                           <span className="play__activity__item__live__video__link">
                                             <a href={data.session.video_link} target="_blank">
                                               <VideoCameraOutlined /> Transmissão
                                             </a>
                                           </span>
                                         )}
                                         {data.session.chat_link != "" && (
                                           <>
                                             <span className="play__activity__item__live__chat__link">
                                               <a href={data.session.chat_link} target="_blank">
                                                 <WechatOutlined /> Chat
                                               </a>
                                             </span>
                                           </>
                                         )}
                                       </p>
                                     </>
                                    }
                                  </div>
                                ) : null}
                              </p>
                            </div>
                          </div>
                        ) : (
                            <>
                              <div className="play__activity__item__number">
                                {data.session.number}
                              </div>
                            </>
                          )}
                      </div>
                    
                </Badge.Ribbon>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className="play__activity__item__content">
                  <Title level={3}><Link to={`/${data.type}/session/${data.session.uid}`}>{data.session.name}</Link></Title>
                  <p className="play__activity__item__content__date">
                    {Core.toUserLocalDateTime(data.session.start_date_time)} até{" "}
                    {Core.toUserLocalTime(data.session.end_date_time)} &nbsp;
                    {!data.live && (
                      <CalendarLinks session={data.session} />
                    )}
                  </p>
                  <p dangerouslySetInnerHTML={{ __html: data.session.description }}></p>
                  <p>{data.type == "event" ? (
                      <Tag color="cyan">
                        <SoundOutlined /> Evento
                      </Tag>
                    ) : (
                        <Tag color="lime">
                          <TrophyOutlined /> Formação
                        </Tag>
                      )}
                    <Link to={`/${data.type}/${data.uid}`}>{data.name}</Link>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
};
