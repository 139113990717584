import { useState, useEffect } from "react";

import {
  Routes,
  Route,
  Link,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

import {
  Provider
} from "react-redux";

import {
  Alert,
  ConfigProvider,
  Layout,
  Menu,
  Button,
  Spin,
  notification,
  theme,
} from "antd";

import {
  MailOutlined,
  BookOutlined,
  PlayCircleOutlined,
  SoundOutlined,
  ClusterOutlined,
  TrophyOutlined,
  HistoryOutlined,
  MenuOutlined,
  CommentOutlined,
} from "@ant-design/icons";

import { RiOpenSourceFill } from "react-icons/ri";

import {
  FaYoutube,
  FaDiscord,
  FaGithub,
  FaFacebook,
  FaLinkedin,
  FaTwitter,
  FaInstagram,
} from "react-icons/fa";

import antLocale_ptPT from "antd/lib/locale/pt_PT";

import _auth from "@netuno/auth-client";
import _service from "@netuno/service-client";

import classNames from "classnames";

import Analytics from "./common/Analytics";

import {
  Store
} from "./redux/store";

import Auth from "./pages/Auth";
import Introduction from "./pages/Introduction";
import Bootcamp from "./pages/Bootcamp";
import LoginPage from "./pages/Login";
import RegisterPage from "./pages/Register";
import Certificate from "./pages/Certificate";
import Main from "./pages/Main";
import RecoveryPage from "./pages/Recovery";
import Profile from "./pages/Profile";
import Events from "./pages/Events";
import Event from "./pages/Event";
import Formations from "./pages/Formations";
import Formation from "./pages/Formation";
import Session from "./pages/Session";
import History from "./pages/History";
import Journey from "./pages/Journey"
import NotFoundPage from "./pages/NotFound";
import Members from "./pages/Members";
import UserProfile from "./pages/UserProfile";

import HeaderUserInfo from "./components/HeaderUserInfo";

import Core from "./Core.js";

import "./common/Config";

import "./styles/App.less";

const { Header, Content, Footer, Sider } = Layout;

const antdVariables = {
  colorPrimary: '#5b5ce1',
  colorLink: '#5b5ce1',
  colorLinkHover: '#424399',
  borderRadius: 4,
  fontSize: 18,
  fontFamily: `
    -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif
  `
}

function App({ }) {
  const [activeMenu, setActiveMenu] = useState("home");
  const [headerButtonMode, setHeaderButtonMode] = useState("login");
  const [collapsed, setCollapsed] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(
    _auth.isLogged() && Core.user == null
  );
  const [sideMenuMobileMode, setSideMenuMobileMode] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const date = new Date();

  useEffect(() => {
    window.scrollTo(0, 0);
    setHeaderButtonMode(location.pathname);

    if (loadingInfo) {
      _service({
        method: "GET",
        url: "people/info",
        success: (response) => {
          if (response.json) {
            Core.user = response.json;
          }
          setLoadingInfo(false);
        },
        fail: (e) => {
          setLoadingInfo(false);
          if (e.response.status == 401) {
            Core.user = null;
            _auth.logout();
            navigate("/login");
          } else {
            setLoadingInfo(false);
            notification["error"]({
              message: "Informações de Login",
              description:
                "Ocorreu um erro a carregar os dados do usuário, por favor tente novamente mais tarde ou entre em contato com o suporte.",
            });
          }
        },
      });
    }
  }, [location]);

  const handleMenuClick = (selectMenu) => {
    if (selectMenu) {
      setActiveMenu(selectMenu);
    }
    window.scrollTo(0, 0);
  };

  const onLogout = () => {
    _auth.logout();
  };

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  if (loadingInfo) {
    return (
      <ConfigProvider
        theme={{
          token: antdVariables,
          algorithm: theme.darkAlgorithm
        }}
      >
        <div className="user__loading">
          <Spin size="large" />
        </div>
      </ConfigProvider>
    );
  }

  return (
    <ConfigProvider
      theme={{
        token: antdVariables,
        algorithm: theme.darkAlgorithm
      }}
      locale={antLocale_ptPT}
    >
      <Provider store={Store}>
        <Layout
          className={
            "page " +
            classNames({ "auth ": _auth.isLogged() }) +
            classNames({ "not-auth ": !_auth.isLogged() }) +
            classNames({ "collapsed ": collapsed })
          }
        >
          {_auth.isLogged() && (
            <Sider
              onBreakpoint={(mobile) => {
                setSideMenuMobileMode(mobile);
              }}
              collapsedWidth={sideMenuMobileMode ? "0" : "80"}
              breakpoint={"md"}
              collapsible
              collapsed={collapsed}
              onCollapse={onCollapse}
              trigger={<MenuOutlined />}
            >
              <div className="logo-container">
                <Link to="/" onClick={() => handleMenuClick("/")}>
                  <img alt="logo" src="/images/logo.svg" />
                </Link>
              </div>
              <Menu
                defaultSelectedKeys={[1]}
                mode="inline"
                items={[
                  {
                    key: 1,
                    label: <Link to="/main">Principal</Link>,
                    icon: <PlayCircleOutlined />
                  },
                  {
                    key: 2,
                    label: <Link to="/bootcamp">Bootcamp</Link>,
                    icon: <ClusterOutlined /> 
                  },
                  {
                    key: 3,
                    label: <Link to="/events">Eventos</Link>,
                    icon: <SoundOutlined /> 
                  },
                  {
                    key: 4,
                    label: <Link to="/formations">Formações</Link>,
                    icon: <TrophyOutlined />
                  },
                  {
                    key: 5,
                    label: <Link to="/members">Membros</Link>,
                    icon: <BookOutlined />
                  },
                  {
                    key: 6,
                    label: <Link to="/journey">Jornada</Link>,
                    icon: <TrophyOutlined />
                  },
                ]}
              />
            </Sider>
          )}
          <Layout>
            <Header
              style={{
                justifyContent: _auth.isLogged() ? "flex-end" : "space-between",
              }}
            >
              {!_auth.isLogged() && (
                <div className="logo-container">
                  <Link to="/" onClick={() => handleMenuClick("/")}>
                    <img src="/images/logo.svg" />
                  </Link>
                </div>
              )}
              <Menu
                className={
                  !collapsed ? "horizontal-menu" : "horizontal-menu-visible"
                }
                selectable={false}
                items={[
                  {
                    key: 2,
                    label: headerButtonMode === "/login" ? (
                      <Link to="/register" className="signin-signup">
                        <Button type="primary">Criar conta</Button>
                      </Link>
                    ) : headerButtonMode === "/register" ? (
                      <Link to="/login" className="signin-signup">
                        <Button type="primary">Iniciar sessão</Button>
                      </Link>
                    ) : (
                      _auth.isLogged() && <HeaderUserInfo onLogout={onLogout} />
                    ),
                    disabled: ['/login', '/register'].includes(headerButtonMode)
                  }
                ]}
              />
            </Header>
            <Content>
              <Routes>
                <Route element={Analytics.init() && <Analytics />} />
                <Route
                  path="/"
                  element={_auth.isLogged() ? (
                    <Navigate to="/main" replace />
                  ) : (
                    <Navigate to="/login" replace />
                  )}
                />
                <Route path="/main" element={<Auth><Main /></Auth>} />
                <Route path="/introduction" element={<Auth><Introduction /></Auth>} />
                <Route path="/bootcamp" element={<Bootcamp />} />
                <Route path="/profile" element={<Auth><Profile /></Auth>} />
                <Route path="/profile/:user" element={<Auth><UserProfile /></Auth>} />
                <Route path="/events" element={<Auth><Events /></Auth>} />
                <Route path="/event/:uid" element={<Auth><Event /></Auth>} />
                <Route path="/event/session/:uid" element={<Auth><Session type="event" /></Auth>} />
                <Route path="/formations" element={<Auth><Formations /></Auth>} />
                <Route path="/formation/session/:uid" element={<Auth><Session type="formation" /></Auth>} />
                <Route path="/formation/:uid" element={<Auth><Formation /></Auth>} />
                <Route path="/history" element={<Auth><History /></Auth>} />
                <Route path="/journey" element={<Auth><Journey /></Auth>} />
                <Route path="/members" element={<Auth><Members /></Auth>} />
                <Route path="/certificate/:code" element={<Certificate />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/recovery" element={<RecoveryPage />} />
                <Route element={<NotFoundPage />} />
              </Routes>
            </Content>
            <Footer>
              <div className="footer__col-1">
                <a
                  href="https://github.com/netuno-org/platform"
                  target="_blank"
                  className="footer__open-source"
                >
                  <RiOpenSourceFill /> Open Source
                </a>
                <a
                  href="https://doc.netuno.org/docs/pt-PT/installation/"
                  target="_blank"
                  className="footer__download"
                >
                  INSTALAÇÃO
                </a>
                <div className="footer__social">
                  <a
                    style={{ fontSize: 24 }}
                    href="https://discord.gg/4sfXG6YWFu"
                    target="_blank"
                  >
                    <FaDiscord />
                  </a>
                  <a
                    style={{ fontSize: 24 }}
                    href="https://github.com/netuno-org"
                    target="_blank"
                  >
                    <FaGithub />
                  </a>
                  <a
                    style={{ fontSize: 24 }}
                    href="https://www.youtube.com/channel/UCYY1Nz6T2NJtP29vba2fqkg"
                    target="_blank"
                  >
                    <FaYoutube />
                  </a>
                  <a
                    style={{ fontSize: 24 }}
                    href="https://twitter.com/netuno_org"
                    target="_blank"
                  >
                    <FaTwitter />
                  </a>
                  <a
                    style={{ fontSize: 24 }}
                    href="https://www.linkedin.com/company/netuno-org/"
                    target="_blank"
                  >
                    <FaLinkedin />
                  </a>
                  <a
                    style={{ fontSize: 24 }}
                    href="https://www.facebook.com/netuno.org/"
                    target="_blank"
                  >
                    <FaFacebook />
                  </a>
                  <a
                    style={{ fontSize: 24 }}
                    href="https://www.instagram.com/netuno_org/"
                    target="_blank"
                  >
                    <FaInstagram />
                  </a>
                </div>
              </div>
              <div className="footer__col-2">
                <p>
                  <CommentOutlined style={{fontSize: 16}}/>
                  <a href="https://forum.netuno.org" target="_blank">forum.netuno.org</a>
                </p>
                <p>
                  <MailOutlined style={{fontSize: 16}}/>
                  <a href="admin@netuno.org">admin@netuno.org</a>
                </p>
                <p>
                  <MailOutlined style={{fontSize: 16}}/>
                  <a href="support@netuno.org">support@netuno.org</a>
                </p>
                <p>
                  Copyright ©&nbsp;<a href="https://www.sitana.pt/">Sitana</a>
                  &nbsp;{date.getFullYear()}
                </p>
              </div>
              {/* <p>Consulte a <a href="https://doc.netuno.org" target="_blank">documentação</a>.</p>
              <p>Participe do <a href="https://forum.netuno.org" target="_blank">forum oficial</a>.</p>
              <p>Inicie na <a href="https://doc.netuno.org/docs/pt-PT/academy/overview/" target="_blank">academia</a>.</p>
              <p>Entre em contato conosco em <a href="https://www.sitana.pt">sitana.pt</a>.</p> */}
            </Footer>
          </Layout>
        </Layout>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
