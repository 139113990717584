import React, {useEffect, useState} from 'react';

import moment from 'moment-timezone';

import { google, outlook, office365, yahoo, ics } from "calendar-link";

import { RiCalendarCheckFill } from 'react-icons/ri';
import { SiGoogle, SiMicrosoftoffice, SiMicrosoftoutlook, SiYahoo } from 'react-icons/si';

import Core from '../../Core';

import './index.less';

export default ({session})=> {
    const { name, number, description, start_date_time, end_date_time } = session;
    const momentStart = moment(Core.toUserLocalDateTime(start_date_time), "YYYY-MM-DD HH:mm");
    const momentEnd = moment(Core.toUserLocalDateTime(end_date_time), "YYYY-MM-DD HH:mm");
    const event = {
        title: name,
        description: `${description}<br/><a href="https://dev.netuno.org">dev.netuno.org</a>`,
        start: momentStart.format(),
        duration: [momentEnd.diff(momentStart, 'hours', true), "hour"],
    };
    return (
        <span className="calendar-links">
          <a href={google(event)} target="_blank"><SiGoogle /></a> &nbsp;
          <a href={outlook(event)} target="_blank"><SiMicrosoftoutlook /></a> &nbsp;
          <a href={office365(event)} target="_blank"><SiMicrosoftoffice /></a> &nbsp;
          <a href={yahoo(event)} target="_blank"><SiYahoo /></a> &nbsp;
          <a href={ics(event)} target="_blank"><RiCalendarCheckFill /></a>
        </span>
    );
};
