import { useState } from "react";
import { Avatar, Col, Modal, Row, Tooltip, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import _service from "@netuno/service-client";

import "./index.less";

const { Title } = Typography;

function ParticipantsList({ participants }) {
  const [showParticipantsModa, setShowParticipantsModa] = useState(false);
  const navigate = useNavigate();
  const maxParticipants = 5;

  const toggleShowParticipantsModal = () => {
    setShowParticipantsModa(!showParticipantsModa);
  };

  return (
    <>
      <Title level={5} className="participants-title" onClick={toggleShowParticipantsModal}>Participantes</Title>
      <Avatar.Group>
        {
          participants?.slice(0, maxParticipants)?.map((participant) => {
            const src = participant.avatar ?
                `/images/people/avatar/${participant.avatar}` :
                '/images/profile-default.png';

            return (
              <Tooltip key={participant.user} title={participant.user}>
                              <Avatar src={src} onClick={() => navigate(`/profile/${participant.user}`)} />
              </Tooltip>
            );
          })
        }
        {
          participants.length > maxParticipants && (
            <Avatar onClick={toggleShowParticipantsModal}>
              + {participants.length - maxParticipants}
            </Avatar>
          )
        }
        <Modal
          open={showParticipantsModa}
          footer={null}
          onCancel={toggleShowParticipantsModal}
          title="Participantes"
          width={320}
        >
        {
          participants.map((participant) => {
            const src = participant.avatar ?
                `/images/people/avatar/${participant.avatar}` :
                '/images/profile-default.png';

            return (
              <Row>
                <Col
                  span={24}
                  className="avatar-col" onClick={() => navigate(`/profile/${participant.user}`)}
                >
                  <Avatar src={src} /> {participant.user}
                </Col>
              </Row>
            );
          })
        }
        </Modal>
      </Avatar.Group>
    </>
  );
}

export default ParticipantsList;
