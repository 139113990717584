import React from "react";
import CardInfor from "../../components/CardInfor";
import { Typography, Row, Col, Collapse, Button } from "antd";
import { Link } from "react-router-dom";
import { useState } from "react";
import _auth from "@netuno/auth-client";
import "./index.less";

const { Title, Text } = Typography;
const { Panel } = Collapse;

const formationUID = '3afd971f-dace-4dd2-adb2-1382d5cac494';

export default function Bootcamp() {
  //const navigate = useNavigate();
  const [content] = useState([
    {
      title: `O que é o Bootcamp Netuno?`,
      content: <div>É uma formação gratuita fullstack com duração de 78h em ambiente desafiador e inspirador com possibilidade de conquistar a primeira experiência profissional.<br/>
                 Essa experiência oferece a chance de desenvolver habilidades em programação e trabalho em equipe, além de ampliar sua visibilidade no mercado de TI. Você terá a oportunidade de se tornar um profissional capacitado e com amplo domínio de tecnologias.</div>,
    },
    {
      title: `Quem poderá participar?`,
      content: <div>Todas as pessoas que queiram aprimorar seus conhecimentos e obter experiência profissional.<br/>
                Há formações específicas e formações de base.</div>,
    },
    {
      title: `Qual será o foco desta formação?`,
      content:  <div>Desenvolver conhecimentos de fullstack web com tecnologias open source.<br/>
                 Principais tecnologias abordadas: 
                  <ul>
                    <li><b>Linux</b>: Sistema operacional utilizado em containers e servidores.</li>
                    <li><b>HTML, CSS e JavaScript</b>: Tecnologias base para o desenvolvimento web.</li>
                    <li><b>PostgreSQL</b>: A base de dados mais moderna.</li>
                    <li><b>Netuno</b>: O backend mais intuitivo com desenvolvimento rápido low-code.</li>
                    <li><b>React</b>: A tecnologia de frontend mais utilizada.</li>
                  </ul>
                </div>,
    },
    {
      title: `Quantas vagas terá a formação?`,
      content: <div>A formação terá 100 vagas e será dado 25 bolsas.</div>,
    },
    {
      title: `O que é a bolsa?`,
      content: <div>Um container de desenvolvimento remoto online do <a href="https://www.veks.net" target="_blank">VEKS.net</a>, que permite estudar e trabalhar online independente do seu computador.<br/>
                 Acesso à experiência profissional com participação em projetos open source.<br/>
                 Mentoria personalizada.
               </div>,
    },
    {
      title: `Como obter uma bolsa?`,
      content: <div>As bolsas serão disponibilizadas a qualquer momento durante o percurso, mas, considerando os critérios de avaliação como empenho, assiduidade, participação, proatividade, e realização dos exercícios.</div>,
    },
    {
      title: `Como será o processo de seleção para a experiência?`,
      content: `De acordo com as presenças, participação nas aulas, realização dos 
                exercícios, e a qualidade técnica do desafio final.`,
    },
    {
      title: `Qual o período de inscrição?`,
      content: `As inscrições serão abertas pelo período de 26/03/2023 à 23/04/2023.`,
    },
    {
      title: `Qual o período da formação?`,
      content: <div>Terá duração de 3 meses. <br/>
                As aulas acontecerão nos horários de:
                <ul>
                  <li><b>Brasília:</b> Terça das 9h até às 12h e Quinta das 15h até às 18h.</li>
                  <li><b>Lisboa:</b> Terça das 12h até às 15h e Quinta das 18h até às 21h.</li>
                </ul>
              </div>,
    },
    {
      title: `Como será a formação?`,
      content: <div>
                 Com aulas teóricas, práticas e 25h de atividades em grupo sendo supervisionado por profissionais. As aulas terão duração total de 78h.<br/>
                 Os bolsistas terão acesso a um container de desenvolvimento do <a href="https://www.veks.net" target="_blank">VEKS.net</a>, assim poderão praticar em ambiente online sem utilizar o computador pessoal. Após a formação, o container ficará indisponível podendo ser contratado a parte posteriormente.
               </div>,
    },
    {
      title: `Terá certificação ao final da formação?`,
      content: `Sim. A emissão será gratuita e elegível apenas aos participantes que obtiverem 
              pontuação superior à 70%.`,
    },
    {
      title: `Quais os requisitos necessários para ser aprovado no processo seletivo?`,
      content: `Para ser aprovado será necessário obter pontuação mínima de 70% nas avaliações
               de presença, participações e exercícios. O participante também terá que  
               apresentar um desafio final mostrando tudo o que foi aprendido nessa formação.`,
    },
    {
      title: `Qual será o vínculo para quem for aprovado nesse processo?`,
      content: `O bolsista aprovado terá a oportunidade de participar em projetos open source por um período de 3 meses. Ao final deste período, o bolsista avaliado poderá se tornar um freelancer na Sitana, uma empresa parceira sediada em Portugal.`,
    },
  ]);
  return (
    <div className="bootcamp">
      <Title level={3}>Bootcamp</Title>
      <div className="bootcamp__container">
        {/*seção 1*/}
        <Row wrap={true} gutter={[50, 50]} justify="start" align={"middle"}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="bootcamp__container--group1">
              <p className="bootcamp__container--description">
                Somos uma comunidade que ajuda a trabalhar com diversas
                tecnologias web open source. Junte-se a nós na plataforma
                Netuno.
              </p>
              <p className="bootcamp__container--description">
                O Bootcamp possui sessões de aulas técnicas sobre full-stack com
                atividades de aprendizagem colaborativa em grupo, partilha-se
                dúvidas, sugestões e desafios. Acelere seus conhecimentos com um
                programa prático e gratuito.
              </p>
              {/* Reajustar o botao para manter a sessão */}
              { _auth.isLogged()
                ? <Button 
                    type="primary" 
                    href={`/formation/${formationUID}`}
                    target="_blank"
                    onClick={() => { }} 
                    ghost>
                    Quero Participar                  
                  </Button>
                : <>
                    <p><Text type="secondary">Clique no botão abaixo, faça o registro, efetue o login, entre na formação do bootcamp e realize a sua participação.</Text></p>
                    <Button
                      type="primary"
                      href="/login"
                      target="_blank"
                      onClick={() => { }} 
                      ghost>
                        Quero Participar
                    </Button>
                  </>
              }
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="bootcamp__container--group2">
              <img src="https://dev.netuno.org/images/event/Community-1200-yzjqm0LG.jpg" />
            </div>
          </Col>
        </Row>
      </div>{" "}
      {/*seção 1*/}
      <div className="bootcamp__container">
        {/*seção 2*/}
        <Row gutter={[50, 50]} wrap={true} justify="start" align={"middle"}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="bootcamp__container--group2">
              <img src="/images/netuno.png" className="image3" />
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="bootcamp__container--group1">
              <Title className="bootcamp__container--title" level={4}>
                Programa de especialização para acelerar sua carreira.
              </Title>
              <p className="bootcamp__container--description">
                Aprenda a desenvolver softwares utilizando as tecnologias web
                como JavaScript, ReactJS, PostgreSQL, Linux, e muito mais, com a
                plataforma Netuno.
              </p>
{ _auth.isLogged()
                ? <Button 
                    type="primary"
                    href={`/formation/${formationUID}`}
                    target="_blank"
                    onClick={() => { }} 
                    ghost>
                    Acontece na Plataforma
                  </Button>
                : <>
                    <p><Text type="secondary">Clique no botão abaixo, faça o registro, efetue o login, entre na formação do bootcamp e realize a sua participação.</Text></p>
                    <Button
                      type="primary"
                      href="/login"
                      target="_blank"
                      onClick={() => { }} 
                      ghost>
                        Acontece na Plataforma
                    </Button>
                  </>
              }
            </div>
          </Col>
        </Row>
      </div>
      {/*seção 2*/}
      <div className="bootcamp__container">
        {/*seção 3*/}
        <Row gutter={[50, 50]} wrap={true} justify="start" align={"middle"}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="bootcamp__container--group1">
              <Title className="bootcamp__container--title" level={4}>
                Desenvolva aplicações web de forma rápida.
              </Title>

              <p className="bootcamp__container--description">
                A versatilidade do Netuno permite a sua integração com um leque
                variado de plataformas, melhorando sua produtividade nos estudos
                e aumentando a performance de seus negócios.
              </p>
              <Button 
                type="primary" 
                href="https://doc.netuno.org/docs/pt-PT/installation/"
                target="_blank"
                onClick={() => {}} 
                ghost> 
                  Baixar Netuno
              </Button>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="bootcamp__container--group2">
              <img src="/images/tecnologias.png" className="image3" />
            </div>
          </Col>
        </Row>
      </div>
      <div className="bootcamp__container">
        {/*seção 3*/}
        <Row gutter={[50, 50]} wrap={true} justify="start" align={"middle"}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="bootcamp__container--group3">
              <a href="https://www.sitana.pt" target="_blank">
                <img src="/images/partner/sitana.svg"/>
              </a>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="bootcamp__container--group1">
              <Title className="bootcamp__container--title" level={4}>
                Oportunidade de Trabalho
              </Title>
              <p className="bootcamp__container--description">
                Com oportunidade para ingressar na sua primeira experência profissional como freelancer full remote para a empresa <a href="https://www.sitana.pt" target="_blank">Sitana</a>, sediada em Portugal.
              </p>
              <Button 
                type="primary" 
                href="https://www.sitana.pt/"
                target={"_blank"}
                onClick={() => {}} 
                ghost> 
                  Sitana.pt
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      {/*seção 3*/}
      <div className="bootcamp__container">
        {/*seção 4*/}
        <div className="bootcamp__container--group1">
          <Title className="bootcamp__container--title" level={4}>
            Acelere cada etapa da sua carreira com o Netuno
          </Title>

          <p className="bootcamp__container--description">
            Conquiste sua experiência e domine ferramentas para evoluir
            profissionalmente.
          </p>
        </div>
        <Row wrap={true} justify={"center"} gutter={[36, 36]}>
          <CardInfor />
        </Row>
      </div>
      {/*seção 4*/}
      <div className="bootcamp__container">
        {/*seção 5*/}
        <Title className="bootcamp__container--title" level={4}>
          Como funcionará o Bootcamp?
        </Title>

        <Row wrap={true} gutter={[50, 50]} justify="start" align={"middle"}>
          <Col span={24}>
            <div className="bootcamp__container--requirements">
              <Collapse size="large">
                {content.map((item, index) => (
                  <Panel header={item.title} key={index}>
                    <p className="text">{item.content}</p>
                  </Panel>
                ))}
              </Collapse>
            </div>
          </Col>
        </Row>
      </div>
      {/*seção 5*/}
    </div>
  );
}
